import { AppStateActions } from "../contexts/AppStateContext"
import CartService from "./cart.service"
import UserService from "./user.service"

const refreshAppState = async (appState) => {
    const response = await UserService.getBids()
    appState.dispatch({type: AppStateActions.SetBids, value: response.data['bids']})
    
    const profileResponse = await UserService.getUserProfile()
    appState.dispatch({type: AppStateActions.SetEmail, value: profileResponse.email})
    appState.dispatch({type: AppStateActions.SetUsername, value: profileResponse.username})
    appState.dispatch({type: AppStateActions.SetVerified, value: profileResponse.verified})
    appState.dispatch({type: AppStateActions.SetProfile, value: profileResponse.profile})
    appState.dispatch({type: AppStateActions.SetUniqueId, value: profileResponse.unique_id})

    const cartResponse = await CartService.getCart()
    appState.dispatch({type: AppStateActions.SetCart, value: cartResponse.data.data})
}

const refreshCart = async (appState) => {
    console.log("REFRESHING CART")
    const cartResponse = await CartService.getCart()
    appState.dispatch({type: AppStateActions.SetCart, value: cartResponse.data.data})
}

const refreshBids = async (appState) => {
    console.log("REFRESHING BIDS")
    const response = await UserService.getBids()
    appState.dispatch({type: AppStateActions.SetBids, value: response.data['bids']})
}

const AppService = {
    refreshAppState,
    refreshCart,
    refreshBids
};

  export default AppService;