import { MDBSpinner } from 'mdb-react-ui-kit';
import React from 'react'

const DateTimeDisplay : React.FC<{value: number, type: string, isDanger: boolean}> = ({value, type, isDanger}) => {
    let v = (<></>)
    if (isNaN(value)) {
      v = <div><MDBSpinner /></div>
    } else {
      v = <p>{value}</p>
    }
    return (
      <div className={isDanger ? 'countdown danger' : 'countdown'}>
        {v}
        <span>{type}</span>
      </div>
    );
  };
  
  export default DateTimeDisplay;