import {
    MDBBadge,
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow
} from "mdb-react-ui-kit";
import { memo, useEffect, useState } from "react";

import AppService from "../../services/app.service";
import AuthService from "../../services/auth.service";
import React from "react";
import { useAppState } from "../../contexts/AppStateContext";
import { useNavigate } from "react-router-dom";

const NavigationMobileFooter: React.FC = () => {

    const appState = useAppState()
    const navigate = useNavigate()

    const navDashboard = () => {
      navigate('/dashboard')
    }
    
    //NOTE: this is a spacer for the lower nav bar on mobile
    return (
      <>
        <div className="spacer-64 d-block d-sm-none">
        </div>
        <div className="nav-footer d-block d-sm-none">
          <div className="nav-flex-footer">
            <div className="item">
              {(AuthService.getCurrentUser()) && (<span onClick={navDashboard} style={{backgroundColor: "#131f59", color: 'white',  padding: '10px 20px', borderRadius: "20px", fontWeight: 800}}>
                    <MDBIcon icon="user"></MDBIcon>{'    '}{appState.state.username}
              </span>)}
            </div>
            <div className="item">
                <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}}>
                    <MDBIcon color='dark'fas icon='bell'></MDBIcon>
                </span>
            </div>
            <div className="item">
              <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}} onClick={navDashboard}>
                  <MDBIcon color='dark' fas icon='shopping-bag'></MDBIcon>
                  {
                    (appState.state.cart?.items && appState.state.cart?.items.length > 0 ? (<span onClick={navDashboard} className="badge-alt">{appState.state.cart.items.length}</span>) : (<></>))
                  }
              </span>
            </div>
          </div>        
        </div>
      </>
    );
  };
  export default memo(NavigationMobileFooter);
  