import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit';

import { Button } from 'react-bootstrap';
import { FileUploader } from '../FileUploader/FileUploader';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const PDashHome : React.FC = () => {

  const navigate = useNavigate()
  const [showPreview, setShowPreview] = useState(false)
  
  return (
    <MDBContainer>
      <h1>Portal</h1>
      <p className="lead">Click on any of the following to get started.</p>
      <MDBRow>
        <MDBCol size='md'>
        <Button onClick={()=>{setShowPreview(!showPreview)}} className="btn-focused btn-fill-96"><MDBIcon fas icon='home'></MDBIcon>{' '} Quick Start</Button>
        </MDBCol>
        <MDBCol size='md'>
        <Button onClick={()=>{navigate('/portal?mode=inventory')}} className="btn-other btn-fill-96"><MDBIcon fas icon='box-open'></MDBIcon>{' '}Inventory</Button>
        </MDBCol>
        <MDBCol size='md'>
        <Button onClick={()=>{navigate('/portal?mode=listings')}} className="btn-other btn-fill-96"><MDBIcon fas icon='bars'></MDBIcon>{' '} Listings</Button>
        </MDBCol>
        <MDBCol size='md'>
        <Button onClick={()=>{navigate('/assets')}} className="btn-other btn-fill-96"><i className="fa fa-image"></i>{' '}Web Assets</Button>
        </MDBCol>
      </MDBRow>
      {
        (showPreview) ? 
          (<>
            <MDBRow>
              <MDBCol size='md'>
                <br />
                <br />
                <Button className="btn-other"><a className="btn-other" href="https://fast-content.s3.us-west-2.amazonaws.com/static/nabify+Product+Sheet+-v0-9-0.xlsx">Download Catalog File</a></Button>
                <br />
                <br />
                <p>Select a <strong>nabify Catalog Sheet Excel file</strong> from your device, then press Upload Catalog. Download the 0.9.0 Excel File from the link. If you have an un-versioned or older version of this sheet, it will be incompatible. Use the migration guidelines on the document to upgrade your previous catalog.</p>
                <br />
                <br />
                <FileUploader />
              </MDBCol>

            </MDBRow>
          </>) : 
          (<>
          
          </>) 
      }

    </MDBContainer>
    
  )
}

export default PDashHome
