import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"

import AuthService from "../services/auth.service"
import { Button } from "react-bootstrap"
import Footer from "../components/navigation/Footer"
import { useAppState } from "../contexts/AppStateContext"
import { useNavigate } from "react-router"

// https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png
// https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png
// https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png

const HowItWorks : React.FC = () => {
    
    const [status, setStatus] = useState('')
    const [message, setMessage] = useState('') 
    const [actionText, setActionText] = useState('')


    const appState = useAppState()
    const navigate = useNavigate()

    useEffect(() => {
        if (AuthService.getCurrentUser()) {
            if (appState.state.bids && appState.state.bids > 0) {
                setStatus('hasBids')
                setMessage('Start Bidding!')
                setActionText('')
            }
            else if (appState.state.bids && appState.state.bids < 1) {
                setStatus('noBids')
                setMessage('Out of bids?')
                setActionText('Buy Bids!')
            }
        } else {
            setStatus('loggedIn')
            setMessage('')
            setActionText('Get Started!')
        }
    }, [appState])

    const handleClick = () => {
        if (status == 'hasBids') {
            navigate('/buybids')
        }
        if (status == 'noBids') {
            navigate('/buybids')
            }
        if (status == 'loggedIn') {
            navigate('/login')
        }
    }


    return (
        <>
            <br />
            <br />
            <MDBContainer>
                <h1 className="text-center"> How It Works </h1>
                <br />
                <MDBRow>
                    <MDBCol xs={12}>
                        <h3>Getting Started</h3>
                        <p>After setting up an account and reloading your nabs, you can start winning*. Choose an auction based on the cause you are passionate about supporting or on the product you are most excited to win, it's all up to you.</p>
                        <p>Using nabify bids “nabs” bid on your selected auction. It’s that easy! 100% of the final auction price goes to the non-profit sponsoring the auction.</p>
                        <p><i>*the most rewarding win is supporting a cause you are passionate about. </i></p>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={12}>
                        <h3>Bid Packs</h3>
                        <p>Look for the <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/nabify_24.png" width="24" height="24" /> icon your device or the bids indicator below the top navigation bar on Mobile. The number next to the icon is the number of nabs you have. Click or tap the indicator to reload.
                        <strong> <br /><br />(Tip: the more you buy the more you get for free!)</strong>
                        </p>

                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={12}>
                        <h3>How Bidding Works</h3>
                        <p>Now that you have loaded up on nabs, you can bid on any of the live nabify auctions.</p>
                        <p>Click the Bid Now button. The auction will add $0.01 to the purchase price.</p>
                        <p>If you bid when there is more than 10 seconds on the timer, the timer will get 10 seconds added to it.  If you bid when the timer is under 10 seconds, the timer will reset to 10 seconds. </p>
                        <strong>(Tip: wait until the last 10 seconds to bid!)</strong>
                        <br />
                        <br />
                    </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                    <MDBCol xs={6} sm={6} md={6} lg={6}>
                        <h5 className="text-center">Over 10 Seconds</h5>
                    </MDBCol>
                    <MDBCol xs={6} sm={6} md={6} lg={6}>
                        <h5 className="text-center">Under 10 Seconds</h5>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="text-center" md={12}>
                        <img width="100%" src="https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png" />
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={5}>
                        <br />
                        <h3>Competition and Winning</h3>
                        <p>The clever, patient, persistent and generous bidder who bids last and there are no other following bids has won the auction when the time expires. The winning bidder gets to purchase the item at the final auction price. 100% of all final purchase prices paid goes to the non-profit.</p>
                        <p>Click on an auction from the Home page to view the bidding details.</p>
                    </MDBCol>
                    <MDBCol md={4}>
                        <br />
                        <br />
                        <ul className="bid_history">
                            <li>You - $0.15 - 4:20:01</li>
                            <li>Bidder 31 - $0.14 - 4:19:21</li>
                            <li>Bidder 12 - $0.13 - 4:18:31</li>
                            <li>Bidder 14 - $0.12 - 4:17:41</li>
                            <li>Bidder 17 - $0.11 - 4:16:24</li>
                            <li>Bidder 32 - $0.10 - 4:15:32</li>
                            <li>Bidder 32 - $0.09 - 4:14:53</li>
                            <li>Bidder 32 - $0.08 - 4:13:22</li>
                            <li>You - $0.07 - 4:12:53</li>
                        </ul>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={8}>
                        <h3>Congruatlations, you win!</h3>
                        <p>If you were the last bidder and no one outbid you when the time expired, you will receive an email to the address you signed up with and the product will show up in your cart for payment and shipping options.</p>

                    </MDBCol>
                    <MDBCol md={4}>

                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={8}>
                        <h3>Donating</h3>
                        <p>nabify users can also choose to donate to the non-profits. Look for the icon in the top right of the auction listing. You can click on the bubble to view information on the non-profit. </p>
                        <strong>(Tip: free & promotional bids work on nabify auctions like regular bids, each bid has a value of $0.0001)</strong>
                    </MDBCol>
                    <MDBCol md={4}>
                        <img className="d-hidden" src="https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part3.png" height="320" />
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol>
                    <div className='shadow-sm p-3 mb-5 bg-white rounded'>
                        <div style={{textAlign: 'center'}}>
                            {(status != 'hasBids') && (<p>{message} <Button onClick={handleClick} style={{borderRadius:'10px'}} className="btn-other" size="lg">{actionText}</Button></p>)}
                        </div>
                    </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </>
    )
}
export default HowItWorks