import { AppStateActions, useAppState } from '../../contexts/AppStateContext';
import {
    MDBCol,
    MDBContainer,
    MDBInputGroup,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBRow,
    MDBSpinner,
    MDBValidation,
    MDBValidationItem
} from 'mdb-react-ui-kit';
import React, { useState } from "react";

import AuthService from '../../services/auth.service';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/navigation/Footer';
import UserService from '../../services/user.service';
import axios from 'axios';
import { useNavigate } from 'react-router';

const ChangeEmail: React.FC<{}> = () => {

    type ChangeEmailError = {
        message: string,
        status: string
    }

    const navigate = useNavigate()

    const [modalVisible, setModalVisible] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [modalTitle, setTitle] = useState("Title")
    const [modalMessage, setMessage] = useState("Description")
    const appState = useAppState()

    const [formValue, setFormValue] = useState({
        email: '-',
      });

    const onChange = (e: any) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const performEmailChange = async () => {
        setProcessing(true)
        try {
            await UserService.changeEmail(formValue.email)
            await AuthService.verifyUserActivate()
            setTitle("Success")
            setMessage("Your e-mail has been updated to " + formValue.email + ". Please check your e-mail to verify your account.")
            setModalVisible(true)
            appState.dispatch({type: AppStateActions.SetEmail, value: formValue.email})
            setTimeout(() => {
                navigate('/verify')
            }, 5000)

        } catch (err) {
            if (!axios.isAxiosError(err)) {
                alert("Unknown error. Please try again later.")
            } else {
                let error: ChangeEmailError = err.response?.data
                console.log(err)
                setTitle("Error")
                setMessage(error.message)
                setModalVisible(true)
                setProcessing(false)
            }
        }

    }
    
    return (
        <>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                    <div className="card card-container" style={{borderRadius: 10}}>
                        <div className="text-center">
                        <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/nabifylogo.png" width="175" height="60" />
                        </div>
                        <br />
                        <p>If you have signed up through Facebook, you can change your email here. Your current email is <Button className="btn-other-sm-tag">{appState.state.email}</Button></p>
                        <MDBValidation className='row text-center' isValidated>
                            <MDBValidationItem feedback=''>
                                <MDBInputGroup textBefore='@'>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='validationCustomEmail'
                                    placeholder={appState.state.email}
                                    onChange={onChange}
                                    required
                                    name='email'
                                    pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                                />
                                </MDBInputGroup>
                            </MDBValidationItem>
                        </MDBValidation>
                        <br />
                        <hr />
                        <br />
                        {processing ? <Button className="btn-other"><MDBSpinner /></Button>: <Button type='submit' className='btn-other' onClick={performEmailChange} >Change EMail</Button> }
                        {/* {(!processing) ? <Button className="btn-other" onClick={verifyEmail}> Send Verification </Button> : <Button className="btn-other"> <MDBSpinner /></Button>}
                        <hr />
                        {(!processing) ? <Button className="btn-other" onClick={navDashboard}> Change E-Mail </Button> : <Button className="btn-other"> <MDBSpinner /></Button>}
                        <hr />
                        {(!processing) ? <Button className="btn-other" style={{fontSize: '1em'}} onClick={navBidderName}> Change Bidder Name </Button> : <Button className="btn-other"> <MDBSpinner /></Button>} */}
                    </div>
                    </MDBCol>
                </MDBRow>
                
            </MDBContainer>
            <Footer />
            <MDBModal show={modalVisible}>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>{modalTitle}</MDBModalTitle>
                        <Button className='btn-other-sm-tag' color='none' onClick={()=>{setModalVisible(false)}}>X</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {modalMessage}
                    </MDBModalBody>

                    <MDBModalFooter>
                        <Button className="btn-other" name="close" onClick={()=>{setModalVisible(false)}}>Close</Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
         </>
    );
};
export default ChangeEmail;

