import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/buttons-special.css"

import * as Yup from "yup";

import AuthService, { login } from '../../services/auth.service';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";

import AppService from "../../services/app.service";
import Button from 'react-bootstrap/Button';
import FacebookLogin from 'react-facebook-login';
import Footer from "../../components/navigation/Footer";
import React from "react";
import { privateEncrypt } from "crypto";
import { useAppState } from "../../contexts/AppStateContext";

interface LoginFormValues {
  email: string;
  password: string;
}

const Login: React.FC<{}> = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate()
  const appState = useAppState()

  useEffect(() => {
    if (AuthService.getCurrentUser()) {
        navigate("/dashboard")
    }
  }, []);

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string().required("This field is required!"),
  //   password: Yup.string().required("This field is required!"),
  // });

  const handleLogin = async (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;
    setMessage("");
    setLoading(true);

    try {
      await login(email, password)
      await AppService.refreshAppState(appState)
      navigate("/home")
    } catch (e) {
      alert("Your e-mail or password is incorrect. Please try again.")
      setLoading(false)
    }
  };

  const responseFacebook = async (response: any) => {
    try {
      await AuthService.loginFB(response)
      await AppService.refreshAppState(appState)
      navigate("/verify")
    } catch (e) {
      alert("There was an error while logging you into Facebook. Please check your credentials and try again.")
    }
  }

  const navigateRegister = async () => {
    navigate('/register')
  }

  const initialValues: {
    email: string;
    password: string;
  } = {
    email: "",
    password: "",
  };
  return (
    <>
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-container" style={{borderRadius: 10}}>
            <div className="text-center">
              <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/nabifylogo.png" width="175" height="60" />
            </div>
            <br />
            <Formik
              initialValues={initialValues}
              onSubmit={handleLogin}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">E-Mail</label>
                  <Field name="email" type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field name="password" type="password" className="form-control" />
                </div>
                <br />
                <Link to="/forgot">
                  Forgot Password?
                </Link>
                <br />
                <div className="form-group" style={{textAlign: 'center'}}>
                  <br />
                  <Button type="submit" className="btn btn-other" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Login</span>
                  </Button>
                </div>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
            <br />
            <hr />
            <Button onClick={()=>{navigateRegister()}} className="btn btn-other">
              Register Now!
            </Button>
            <br />
            <p>*By clicking Login With Facebook, I agree to the <a href="/terms">Terms and Conditions</a></p>
            <br />

            <div className="text-center">
              <FacebookLogin
                appId="319001158535270"
                fields="name,email"
                callback={responseFacebook}
                disableMobileRedirect={true}
                cssClass="ua-facebook-login-button btn btn-other btn-fb-fit"

              />
            </div>

          </div>
        </div>

      </div>
    </div>
    <Footer />
    </>
  );
};
export default Login;