import { AppStateActions, useAppState } from '../contexts/AppStateContext';
import { MDBContainer, MDBListGroup, MDBListGroupItem } from 'mdbreact'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { APICartResponse } from "../models/Cart";
import AccountCart from './dashboard/AccountCart';
import AccountInfo from './dashboard/AccountInfo';
import AccountOrders from './dashboard/AccountOrders';
import AuthService from "../services/auth.service";
import Footer from "../components/navigation/Footer";
import { MDBIcon } from 'mdb-react-ui-kit';
import React from "react";
import UserService from "../services/user.service";

const Dashboard: React.FC = () => {

    let initialMode = new URLSearchParams(window.location.search).get("mode");

    const [mode, setMode] = useState(initialMode)

    const [username, setUsername] = useState()
    const [email, setEmail] = useState()
    const [error, setError] = useState("")
    const [verified, setVerified] = useState(false)
    const [cart, setCart] = useState<APICartResponse>()

    const navigate = useNavigate()
    const appState = useAppState()
    const pathname = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!AuthService.getCurrentUser()) {
            navigate("/login")
        } else {
            getProfile()
            setError("You are succesfully logged in")
        }
    }, [pathname]);

    const verifyEmail = async () => {
        try {
            const user = await AuthService.verifyUserActivate()
            alert(user)
            // setUsername(user.username)
            // setEmail(user.email)
        } catch (e: any) {
            if (e.response?.data?.message?.includes('Expired refresh token'))
                navigate('/login')
            else
                setError("There has been an error!")
        }
    }

    const getProfile = async () => {
        (async () => {
            // try {
            //     const user = await UserService.getUserProfile()
            //     setUsername(user.username)
            //     setEmail(user.email)
            // } catch (e: any) {
            //     if (e.response?.data?.message?.includes('Expired refresh token'))
            //         navigate('/login')
            //     else
            //         setError("There has been an error!")
            // }
        })();
    }

    const logOut = () => {
        AuthService.logout();
        appState.dispatch({type: AppStateActions.ClearState});
        navigate("/login")
    };    

    let currentView = (<div></div>)
    let checkoutView = (<div></div>)

    let overrideMode = (initialMode != mode) ? initialMode : mode

    switch(overrideMode) {
      case "dashboard":
        currentView = (
          <div>
            {checkoutView}
          </div>
        )
        break
      case "purchases":
        currentView = (<AccountOrders></AccountOrders>)
        break
      case "profile":
        currentView = (<AccountInfo></AccountInfo>)
        break
      default:
        currentView = (
          <div>
            <AccountCart></AccountCart>
          </div>
        )
        break
    }


    return (
        <div>
            <div className="container">
                <br />
                <div className="row">
                <div className="col-md-3">
                <div className="panel panel-default sidebar-menu">
                    <div className="panel-heading">
                        <h4>Bidder Info</h4>
                        <p><MDBIcon fas icon='coins'></MDBIcon><span style={{marginLeft: 10}}>{appState.state.username}</span></p>
                    </div>
                    <div className="panel-body">
                    <hr />
                    <h4>Account Information</h4>
                    <div className="verify">
                        <span className="left">{appState.state.email}</span>
                        <br />
                        <span className="right">{appState.state.verified ? 'Verified' : 'Not Verified'}</span>
                        <br/>
                    </div>
                    <hr />
                    {(appState.state.profile?.current_company) && (
                      <>
                        <h4>Organization Information</h4>
                        <div className="verify">
                            <span className="left">{appState.state.profile.current_company.company_name}</span>
                            <br />
                            <span className="right">{appState.state.verified ? 'Verified' : 'Not Verified'}</span>
                            <br/>
                        </div>
                        <hr />
                      </>
                    )}
                    <br />
                    <MDBContainer>
                        <MDBListGroup style={{ width: "12rem" }}>
                          {(appState.state.profile?.current_company) && (<MDBListGroupItem hover onClick={()=>{navigate('/portal')}}><span style={{float: 'right'}}><MDBIcon fas icon='columns'></MDBIcon></span>To Seller Portal</MDBListGroupItem>)}
                        </MDBListGroup>
                    </MDBContainer>
                    <br />
                    <MDBContainer>
                        <MDBListGroup style={{ width: "12rem" }}>
                        <MDBListGroupItem hover onClick={()=>{navigate('/dashboard?mode=cart')}}><span style={{float: 'right'}}><MDBIcon fas icon='shopping-bag'></MDBIcon></span>Cart</MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{navigate('/dashboard?mode=purchases')}}><span style={{float: 'right'}}><MDBIcon fas icon='money-bill'></MDBIcon></span> Purchases </MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{logOut()}}><span style={{float: 'right'}}><MDBIcon fas icon='power-off'></MDBIcon></span> Logout</MDBListGroupItem>
                        </MDBListGroup>
                    </MDBContainer>

                    <br />
                    </div>
                </div>
                </div>
                <div className="col-md-9" id="customer-orders">
                    {currentView}
                </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Dashboard;