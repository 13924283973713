import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit"
import React, { Component, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';

export const Banner: React.FC = () => {
    
    const [index, setIndex] = useState<number>(0);

    const handleSelect = (selectedIndex: number, e: any) => {
      setIndex(selectedIndex);
    };
  


    return (
        <div style={{backgroundColor: "#333"}}>
            <Carousel fade activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <a href="https://www.feedingamerica.org/our-work/hunger-relief-programs?s%20src=Y20XP1B1Y&s%20subsrc=m&s%20keyword=feeding%20america%20org&&gclid=EAIaIQobChMI%20qXRyPWi6AIVCsNkCh0TdgxAEAAYASADEgL31%20D%20BwE=" rel="noopener noreferrer" target="_blank">
                        <img
                            src="https://fast-content.s3.us-west-2.amazonaws.com/images/banner_feeding.png"
                            alt="First slide"
                            width="800"
                            height="290"
                            style={{margin: '0 auto', 'objectFit': 'contain', width: '100%', height: '290px'}}
                            />
                            </a>
                    <Carousel.Caption>
                    <h3></h3>
                    <p></p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>

    );
  
}
