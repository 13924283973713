import './index.css';
import 'mdbreact/dist/css/mdb.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import App from './App.tsx';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';


let backendHost;
//we are not using this
// const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;
const protocol = window && window.location && window.location.protocol;

if (protocol === "https:" && (hostname === 'nabify.com' || hostname === 'neo.nabify.com' || hostname === 'www.nabify.com' || hostname === 'frontend.nabify.com')) {
  backendHost = 'https://nabify-neo-org-wsocket-production.up.railway.app'
}
else if (protocol === "https:" && (hostname === 'staging.nabify.com')) {
  backendHost = 'https://api.staging.nabify.com'
} 
else {
  backendHost = 'http://127.0.0.1:8000'
}

export const API_ROOT = `${backendHost}`;

const rootElement = document.getElementById("root")

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
      <App />
  ,rootElement);
} else {
  ReactDOM.render(
      <App />
  ,rootElement);
}


// serviceWorkerRegistration.register();

reportWebVitals();