import { AppStateActions, useAppState } from '../../contexts/AppStateContext'
import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import AuthService from '../../services/auth.service'
import Footer from '../../components/navigation/Footer'
import { SSRProvider } from 'react-bootstrap'

const PostVerify: React.FC = () => {

  const navigate = useNavigate()
  const appState = useAppState()
  const [message, setMessage] = useState('')
  const [processing, setProcessing] = useState(true)

  const { id } = useParams() 

  useEffect(() => {
    (async () => {
      try {
        const response = await AuthService.verifyUserPerform(id)
        console.log(response?.status)
        
        
        // setUsername(user.username)
        // setEmail(user.email)
        setProcessing(false)
        setMessage("Success! Your account has been verified. If this is not the browser you are logged into, please return to that browser. Redirecting...")
        appState.dispatch({type: AppStateActions.SetVerified, value: true})
        setTimeout(() => {
          navigate('/home')
        }, 5000)
      }
      catch (e: any) {
        setProcessing(false)
        setMessage('An unknown error has occurred.')
      }
    })()
  },[])

  return (
    <>
      <MDBContainer>
          <MDBRow>
              <MDBCol>
                <div className="card card-container" style={{borderRadius: 10}}>
                    <div className="text-center">
                    <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/nabifylogo.png" width="175" height="60" />
                    </div>
                    <br />
                    {
                      (processing) ? 
                      (<>
                        <h5>We are currently verifying your account...</h5>     
                        <br />
                        <div className='text-center'>
                          <MDBSpinner />              
                        </div>
     
                      </>) : 
                      (<>
                        <h4>
                          {message}
                          <br />
                          <div className='text-center'>
                            <MDBSpinner />              
                          </div>
                        </h4>
                      </>)
                    }
                  </div>
              </MDBCol>
          </MDBRow>
      </MDBContainer>
      <Footer />
    </>
  )
}


export default PostVerify;