import Footer from "../components/navigation/Footer";
import React from "react";

export const PurchaseSuccess: React.FC = () => {
  return (
    <div style={{ marginTop: 40, paddingTop: 20 }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-1"></div>
          <div className="col-xs-10">
            <p>Thank you for your purchase! Please wait up to 24 hours to receive your shipping information. </p>
          </div>
          <div className="col-xs-1"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
