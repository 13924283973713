import { useEffect, useState } from "react";

import React from "react";
import { useNavigate } from "react-router-dom"

const AccountOrders: React.FC = () => {
    return(
    <>
        <br />
        <div className="box">
        <h1>Purchases</h1>
        <p className="lead">You can view the status of your orders here.</p>
        <p className="text-muted">If you have any questions, please feel free to <a rel="noopener noreferrer" target="_blank" href="/contact">contact us</a>, our customer service center is working for you 24/7.</p>
        <hr />
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Auction Won</th>
                <th>Action</th>
                <th>MSRP</th>
                <th>You Saved</th>
                <th>You Pay!</th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>)
}

export default AccountOrders;