import api from "./api";

const getBidpacks = () => {
  return api.get("/bidpacks/");
};

const postBidpackToCart = (unique_id) => {
  return api.post('/bidpacks/buy/',{unique_id})
}

const postPromocode = (code) => {
  return api.post('/promocode',{code})
}


const BidpackService = {
  getBidpacks,
  postBidpackToCart,
  postPromocode
};

export default BidpackService;
