import { MDBCol, MDBContainer, MDBIcon, MDBInput, MDBInputGroup, MDBRow, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from "react";

import { APICartResponse } from "../../models/Cart";
import { APICheckoutValidateResponse } from "../../models/Checkout";
import { AddressBlock } from "../../models/AddressBlock";
import AuthService from "../../services/auth.service";
import Button from "react-bootstrap/esm/Button";
import CartService from "../../services/cart.service";
// import CheckoutForm from "../../components/checkout/CheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import Footer from "../../components/navigation/Footer";
import Form from 'react-bootstrap/Form';
import React from "react";
import { STATUS_CODES } from "http";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";

const CheckoutForm: React.FC<{addressBlock: AddressBlock}> = ({addressBlock}) => {

    const stripePromise = process.env.NODE_ENV === "production" ? loadStripe('pk_live_upito2mmVqEDDQL8TwC0TTW9') : loadStripe('pk_test_GH7dYTHHjL3SQGpDN5Y0Sxla')

    const [checkoutAPIResponse, setCheckoutAPIResponse] = useState<APICheckoutValidateResponse>()
    const [canPay, setCanPay] = useState(false)
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
      (async () => {
        await validateCharge()
      })() 
    }, [])

    const validateCharge = async () => {
        (async () => {
            try {
                let stripeValidationReponse: APICheckoutValidateResponse = await CartService.validateCharge(addressBlock)
                setCheckoutAPIResponse(stripeValidationReponse)
                if  (!(stripeValidationReponse.data.status == "error"))
                    setCanPay(true)
                else
                    setHasError(true)
                
            } catch (e: any) {
                console.log(e)
                console.log("NO VALIDATE")
            }
        })();
    }

    return (
        // <form onClick={handleSubmit}>      
        <>          
        <MDBRow>
            <MDBCol>
                <h3>Subtotal: ${checkoutAPIResponse?.data.total.toFixed(2)}</h3>
            </MDBCol>
            <MDBCol>
                <h3>Shipping: ${checkoutAPIResponse?.data.shipping.toFixed(2)}</h3>
            </MDBCol>
            <MDBCol>
                <h3>Total: ${checkoutAPIResponse?.data.amount.toFixed(2)}</h3>
            </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
            <MDBCol md={12}>
                {

                }
                {
                    
                    (canPay) && 
                        <Elements stripe={stripePromise} options={{"clientSecret":checkoutAPIResponse?.data.stripe.client_secret}}>
                            <StripeForm />
                        </Elements>
                }
            </MDBCol>
        </MDBRow>  
        </>
    )
}

const StripeForm: React.FC = () => {
    
    const prodConfig = {
        connectionURL: "https://api.nabify.com/api/",
        frontEndURL: "https://nabify.com/",
        projectId: "nabify-webapp"
      };
      
    const devConfig = {
        connectionURL: "http://localhost:8000/api/",
        frontEndURL: "http://localhost:3000/",
        projectId: "nabify-test-dev"
    };

    const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

    const navigate = useNavigate()
    const stripe = useStripe()
    const elements = useElements()

    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (event: React.SyntheticEvent) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setProcessing(true)
    
        if (!stripe || !elements) {
          return;
        }

        console.log(elements)
    
        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: config.frontEndURL + "checkout",
          },
        });

        // console.log(result)
    
        // if (result.error) {
        //   alert(result.error.message);
        // } else {
        //     alert('wait')
        //     navigate('purchaseSuccess');
        //   // Your customer will be redirected to your `return_url`. For some payment
        //   // methods like iDEAL, your customer will be redirected to an intermediate
        //   // site first to authorize the payment, then redirected to the `return_url`.
        // }
    };

    return (
        <form onSubmit={handleSubmit}>  
            <PaymentElement />
            <MDBRow> 
            </MDBRow>
            <br />
            <br />
            <MDBRow>
                <MDBCol md={4}>                    
                </MDBCol>
                <MDBCol md={4}>
                    <p style={{textAlign: 'center'}}>Select Purchase to complete the order.<br /><br /> <Button className="btn-other" disabled={processing} onClick={handleSubmit}>Purchase</Button>   </p>   
                </MDBCol>
            </MDBRow>     
        </form>
    )
}

const SuccessForm: React.FC = () => {

    const stripe = useStripe();
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState('')

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    const navigate = useNavigate()
    
    useEffect(() => {
        (async () => {
            if (clientSecret) {

                if (!stripe) {
                    return;
                }
              
                const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
                console.log(paymentIntent)
                switch (paymentIntent?.status) {
                    case "succeeded":
                        setStatus('success')
                        setTitle("Congratulations!")
                        setMessage("Thank you for your purchase. An e-mail has been sent with a receipt.")
                        break;
                    case "processing":
                        setStatus('processing')
                        setTitle("One moment...")
                        setMessage("Your order is still being processed, please wait up to 30 minutes for processing.")
                        break;
                    default:
                        break;
                }
            }
        })()

        setTimeout(() => {
            navigate('/home')
        }, 5000)
       
    })

    return (
        <>
            <div className="container text-center">
                <br />
                <br />
                <br />
                
                <p>{(status == 'success') && (<MDBIcon size="8x" icon="check"></MDBIcon>)}</p>
                <p>{(status == 'processing') && (<MDBIcon size="8x" icon="stopwatch"></MDBIcon>)}</p>
                <h2>{title}</h2>
                <p>{message}</p> 
            </div>
        </>
    )
}

const AccountCheckout: React.FC = () => {

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    const states = [
        {"value":"AL","text":"Alabama"},
        {"value":"AK","text":"Alaska"},
        {"value":"AZ","text":"Arizona"},
        {"value":"AR","text":"Arkansas"},
        {"value":"CA","text":"California"},
        {"value":"CO","text":"Colorado"},
        {"value":"CT","text":"Connecticut"},
        {"value":"DE","text":"Delaware"},
        {"value":"DC","text":"District Of Columbia"},
        {"value":"FL","text":"Florida"},
        {"value":"GA","text":"Georgia"},
        {"value":"HI","text":"Hawaii"},
        {"value":"ID","text":"Idaho"},
        {"value":"IL","text":"Illinois"},
        {"value":"IN","text":"Indiana"},
        {"value":"IA","text":"Iowa"},
        {"value":"KS","text":"Kansas"},
        {"value":"KY","text":"Kentucky"},
        {"value":"LA","text":"Louisiana"},
        {"value":"ME","text":"Maine"},
        {"value":"MD","text":"Maryland"},
        {"value":"MA","text":"Massachusetts"},
        {"value":"MI","text":"Michigan"},
        {"value":"MN","text":"Minnesota"},
        {"value":"MS","text":"Mississippi"},
        {"value":"MO","text":"Missouri"},
        {"value":"MT","text":"Montana"},
        {"value":"NE","text":"Nebraska"},
        {"value":"NV","text":"Nevada"},
        {"value":"NH","text":"New Hampshire"},
        {"value":"NJ","text":"New Jersey"},
        {"value":"NM","text":"New Mexico"},
        {"value":"NY","text":"New York"},
        {"value":"NC","text":"North Carolina"},
        {"value":"ND","text":"North Dakota"},
        {"value":"OH","text":"Ohio"},
        {"value":"OK","text":"Oklahoma"},
        {"value":"OR","text":"Oregon"},
        {"value":"PA","text":"Pennsylvania"},
        {"value":"RI","text":"Rhode Island"},
        {"value":"SC","text":"South Carolina"},
        {"value":"SD","text":"South Dakota"},
        {"value":"TN","text":"Tennessee"},
        {"value":"TX","text":"Texas"},
        {"value":"UT","text":"Utah"},
        {"value":"VT","text":"Vermont"},
        {"value":"VA","text":"Virginia"},
        {"value":"WA","text":"Washington"},
        {"value":"WV","text":"West Virginia"},
        {"value":"WI","text":"Wisconsin"},
        {"value":"WY","text":"Wyoming"}
    ]

    const [cart, setCart] = useState<APICartResponse>()
    const [shipping, showShipping] = useState(false)
    const [formValidated, setFormValidated] = useState(false)
    const [shippingVerified, setShippingVerified] = useState(true)
    const [serverValidated, setServerValidated] = useState(false)

    const [respMessage, setMessage] = useState('') 
    const [cardErrors, setErrors] = useState([])
    const [addressInfo, setAddressInfo] = useState<AddressBlock>()

    const [canPurchase, setCanPurchase] = useState(clientSecret ? false : true)
    const [hasError, setHasError] = useState(false)

    const [formValue, setFormValue] = useState({
        first: '',
        mid: '',
        last: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        validated: true
    });

    const stripePromise = loadStripe('pk_test_GH7dYTHHjL3SQGpDN5Y0Sxla');

    const navigate = useNavigate()
    useEffect(() => {

        if (!AuthService.getCurrentUser()) {
            navigate("/login")
        } else {
            if (canPurchase) {
                getCart()
                // validateCharge()
            }
        //   setError("You are succesfully logged in")
        }
    }, []);



    const onChange = (e: any) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
        
        if (formValue.first != '' && formValue.first != '' && formValue.address != '' && formValue.city != '' && formValue.zip != '') {
            setFormValidated(true)
        }

    };

    const onStateChange = (e: any) => {
        setFormValue({...formValue, "state": e.target.value})
    }

    const getCart = async () => {
        (async () => {
            try {
                let cartResponse : APICartResponse = await CartService.getCart()
                if (cartResponse.data.data.items.length > 0) {
                    checkShipping(cartResponse)
                    setCart(cartResponse)
                } else {
                    setCanPurchase(false)
                }
            } catch (e: any) {
                console.log("NO CART")
            }
        })();
    }

    const checkShipping = (cart: APICartResponse) => {
        let { data }  = cart.data
        let hideShipping = true
        if ( data.items && data.items.length > 0) {
          for (let x of data.items) {
            if (!x?.procedure) {
                showShipping(true)
                setShippingVerified(false)
                return
            }
          }
        }
    }

    const verifyAddress = async () => {
        try {
            const shipping = await CartService.verifyShipping(formValue)
            console.log("S")
            let ma = shipping.data?.data[0]
            if (ma) {
                console.log("MA")
                let match = ma['matched_address']
                console.log("M")
                if (match) {
                    let address = match['address_line1']
                    let address2 = match['address_line2']
                    let city = match['city_locality']
                    let zip = match['postal_code']
                    let state = match['state_province']

                    let inputAddr = document.getElementById('validationCustomAddress')
                    if(inputAddr) (inputAddr as HTMLInputElement).value = address

                    let inputAddr2 = document.getElementById('validationCustomAddress2')
                    if(inputAddr2) (inputAddr2 as HTMLInputElement).value = address2

                    let inputCity = document.getElementById('validationCustomCity')
                    if(inputCity) (inputCity as HTMLInputElement).value = city

                    let inputStateName = document.getElementById('validationCustomStateName')
                    if(inputStateName) (inputStateName as HTMLInputElement).value = state

                    let inputZIP = document.getElementById('validationCustomZIP')
                    if(inputZIP) (inputZIP as HTMLInputElement).value = zip

                    console.log(address, address2, city, state, zip)
                    setFormValue({ ...formValue, 
                        address, address2, city, state, zip
                    })
                }
            }
            setServerValidated(true)
        } catch (e) {
            setServerValidated(false)
        }

    }

    const confirmAddress = async () => {
        console.log("VERIFY")
        setShippingVerified(true)

    }

    return(
        <>
            {canPurchase ?
                (
                    <>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md={12}>
                                    <br />
                                    <br />
                                    <h1>Card Details</h1>
                                    <p className="lead">Checkout is simple! Enter your credit card information below.</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={12}>
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th>Items</th>
                                            <th>MSRP</th>
                                            <th>You Pay!</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cart ? 
                                                (cart.data.data.items?.map((item) => {
                                                    return (
                                                    <tr key={item.unique_id}>
                                                        <td>
                                                        <img src={item.listing_info.product_info.image} width="24" height="24" />  {item.listing_info.product_info.name}
                                                        </td>
                                                        <td>
                                                        <span style={{"fontSize": "1.2em"}}>${item.listing_info.product_info.msrp}</span>
                                                        </td>
                                                        <td>
                                                        <span style={{"fontSize": "1.2em", "fontWeight": "bold", color: "#5cb85c"}}>${item.price}</span>
                                                        </td> 
                                                    </tr>
                                                    )
                                                })) : 
                                                <tr></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                </MDBCol>
                            </MDBRow>
                            <hr />
                            { shipping &&
                                (<MDBValidation isValidated>
                                    <MDBRow>
                                        <MDBCol md={12}>
                                            <br />
                                            <br />
                                            <h1>Shipping Details</h1>
                                            <p className="lead">Please enter your shipping details.</p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBValidationItem feedback='' className='col-md-5'>
                                            <p style={{marginBottom: '8px'}}>First Name</p>
                                            <MDBInputGroup>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='validationCustomFirstname'
                                                    placeholder=''
                                                    onChange={onChange}
                                                    required
                                                    name='first'
                                                    maxLength={30}
                                                    disabled={shippingVerified}
                                                    pattern="[A-Za-z0-9 ]{1,30}"
                                                />
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback='' className='col-md-2'>
                                            <p style={{marginBottom: '8px'}}>Middle Name</p>
                                            <MDBInputGroup>
                                                <MDBInput
                                                    type='text'
                                                    id='validationCustomMidname'
                                                    placeholder=''
                                                    onChange={onChange}
                                                    name='mid'
                                                    disabled={shippingVerified}
                                                    maxLength={30}
                                                />
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback='' className='col-md-5'>
                                            <p style={{marginBottom: '8px'}}>Last Name</p>
                                            <MDBInputGroup>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='validationCustomLastname'
                                                    placeholder=''
                                                    onChange={onChange}
                                                    required
                                                    name='last'
                                                    maxLength={30}
                                                    disabled={shippingVerified}
                                                    pattern="[A-Za-z0-9 ]{0,30}"
                                                />
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                    </MDBRow>
                                    <br />
                                    <MDBRow>
                                        <MDBValidationItem feedback='' className='col-md-8'>
                                            <p style={{marginBottom: '8px'}}>Address</p>
                                            <MDBInputGroup>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='validationCustomAddress'
                                                    placeholder='Address'
                                                    onChange={onChange}
                                                    required
                                                    name='address'
                                                    maxLength={30}
                                                    disabled={shippingVerified}
                                                    pattern="[A-Za-z0-9 ]{1,30}"
                                                />
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback='' className='col-md-4'>
                                            <p style={{marginBottom: '8px'}}>Address 2</p>
                                            <MDBInputGroup>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='validationCustomAddress2'
                                                    placeholder='Apt/Suite'
                                                    onChange={onChange}
                                                    name='state'
                                                    maxLength={30}
                                                    disabled={shippingVerified}
                                                    pattern="[A-Za-z0-9 ]{1,30}"
                                                    
                                                />
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                    </MDBRow>
                                    <br />
                                    <MDBRow>
                                        <MDBValidationItem feedback='' className='col-md-6'>
                                            <p style={{marginBottom: '8px'}}>City</p>
                                            <MDBInputGroup>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='validationCustomCity'
                                                    placeholder='City'
                                                    onChange={onChange}
                                                    required
                                                    name='city'
                                                    maxLength={30}
                                                    disabled={shippingVerified}
                                                    pattern="[A-Za-z0-9 ]{1,30}"
                                                />
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback='' className='col-md-2'>
                                            <p style={{marginBottom: '8px'}}>State</p>
                                            <MDBInputGroup>
                                                <Form.Select onChange={onStateChange}>
                                                    {
                                                        states.map((state) => {
                                                            return (<option value={state.value}>{state.text}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                        <MDBValidationItem feedback='' className='col-md-4'>
                                            <p style={{marginBottom: '8px'}}>ZIP</p>
                                            <MDBInputGroup>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='validationCustomZIP'
                                                    placeholder='ZIP Code'
                                                    onChange={onChange}
                                                    name='zip'
                                                    maxLength={30}
                                                    required
                                                    disabled={shippingVerified}
                                                    pattern="[A-Z0-9-]{1,15}"
                                                />
                                            </MDBInputGroup>
                                        </MDBValidationItem>
                                    </MDBRow>
                                    <br />
                                    <MDBRow>

                                        <MDBCol style={{textAlign: 'center'}}>
                                            {(formValidated && !serverValidated) && (<Button className="btn-other" onClick={()=>{verifyAddress()}}>Verify Address</Button>) }
                                            {(serverValidated) && (
                                                <>
                                                    <p>Your information has been updated to match address verification. Please make any final adjustments.</p>
                                                    <Button className="btn-other" disabled={shippingVerified} onClick={()=>{confirmAddress()}}>Confirm Address</Button>
                                                </>
                                            ) }                                           
                                        </MDBCol>

                                    </MDBRow>
                                </MDBValidation>
                                )
                            }

                            <hr />
                            {(shippingVerified) ? (<CheckoutForm addressBlock={formValue}/>) : (<></>)}
                            <br />
                            <hr />
                            <br />
                        </MDBContainer>
                        <Footer />
                    </>
                ) : 
                (
                    <Elements stripe={stripePromise}>
                        <SuccessForm>
                        </SuccessForm>
                    </Elements>
                )
            }
        </>
    )
}

export default AccountCheckout;
