import React, { Component } from 'react';

import { MDBIcon } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router';

const Footer: React.FC = () =>  {

    const navigate = useNavigate()
    // return (
    //     <></>
    // )
    return (
        <footer id="footer" className='shadow-sm p-3 mb-5 bg-white rounded'>
            <div className="container">
                <hr />
                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <h4>Pages</h4>
                        <ul style={{listStyleType: 'none'}}>
                            <li><a className="mimic-link" onClick={()=>{navigate('/about')}}>About Us</a>
                            </li>
                            <li><a className="mimic-link" onClick={()=>{navigate('/terms')}}>Terms and Conditions</a>
                            </li>
                            <li><a className="mimic-link" onClick={()=>{navigate('/privacy')}}>Privacy Policy</a>
                            </li>
                            <li><a className="mimic-link" onClick={()=>{navigate('/faq')}}>FAQ</a>
                            </li>
                            <li><a className="mimic-link" onClick={()=>{navigate('/contact')}}>Contact Us</a>
                            </li>
                        </ul>
                        <hr />
                        <h4>Stay In Touch</h4>
                        <p className="social">
                            <a href="https://www.facebook.com/bidbuybrag" className="px-3" data-animate-hover="shake"><MDBIcon size="2x" fab icon="facebook" /></a>
                            <a href="https://www.twitter.com/bidbuybrag" className="px-3" data-animate-hover="shake"><MDBIcon size="2x" fab icon="twitter" /></a>
                            <a href="https://www.instagram.com/bidbuybrag" className="px-3" data-animate-hover="shake"><MDBIcon size="2x" fab icon="instagram" /></a>
                            <a href="mailto:support@nabify.com" className="px-3" data-animate-hover="shake"><MDBIcon size="2x" fas icon="envelope" /></a>
                        </p>
                        <hr className="hidden-md hidden-lg hidden-sm" />
                    </div>
                    <div className="col-md-3 col-sm-6">

                    </div>
                    <div className="col-md-3 col-sm-6">
                        <h4>Get The News</h4>
                        <p className="smalltext">Want to stay updated with our promotions? Enter your email address here to keep up with our campaigns.</p>
                        <button className="btn btn-other" type="button">Subscribe!</button>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;