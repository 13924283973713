import { AppStateActions, useAppState } from "../contexts/AppStateContext";
import { Button, ToastContainer } from "react-bootstrap";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import { MDBBtn, MDBCollapse, MDBContainer, MDBIcon, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"

import AuthService from "../services/auth.service";
import CountDownTimer from "../components/countdown/CountDownTimer";
import Footer from "../components/navigation/Footer";
import { Helmet } from "react-helmet";
import Listing from "../models/Listing";
import ListingService from "../services/listing.service";
import Moment from "react-moment";
import React from "react";
import Transaction from "../models/Transactions";
import UserService from "../services/user.service";

// import { FacebookS}

const GenericListing: React.FC = () => {
  
    const { id } = useParams() 

    const [descriptionOpen, setDescriptionOpen] = useState(false)
    const [fundraisingOpen, setFundraisingOpen] = useState(false)
    const [bidAmount, setBidAmount] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [canBid, setCanBid] = useState(false)

    const [listing, setListing] = useState<Listing>()

    const [] = useState('')

    const navigate = useNavigate()
    const appState = useAppState()
    const pathname = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
        (async () => {
            try {
                let { data } = await ListingService.getIndividualListing(id);
                let listing : Listing = data.data
                setListing(listing)
                setLoading(false)
                setCanBid(true)
                appState.dispatch({type: AppStateActions.UpdateListing, value: listing})
                setDescriptionOpen(true)
                setFundraisingOpen(true)
            } catch (e) {

            }
        })()
    }, [pathname])


  const inputSilentListingBid = async(e: any) => {
    let val = e.target?.value
    setBidAmount(val)
  }

  const bidOnListing = async(unique_id: string) => {
    setCanBid(false)
    setTimeout(() => {
      setCanBid(true)
    }, 2000)
    try {
        await ListingService.bidOnListing(unique_id)
        setTimeout(async () => {

            let res = await UserService.getBids()
            appState.dispatch({type: AppStateActions.SetBids, value: res.data.bids})
        }, 1000)
    } catch (e) {
        setCanBid(false)
        console.log(e)
    }
  }

  const bidSilentListing = async(e: any) => {
    // let {bidAmount} = this.state
    // let {data} = this.props.listing
    // if (bidAmount >= parseFloat(data.current_bid) + parseFloat(data.bid_increment)) {
    //   this.props.bidOnSilentListing(data.unique_id, bidAmount).then((res)=> {
    //     setTimeout(()=>{
    //       this.setState({"processing":false})
    //     },1000)
    //     this.setState({"processing":true})
    //   }).catch((err)=> {
    //     console.log(err)
    //   })
    // } else {
    //   let amount = (parseFloat(data.current_bid) + parseFloat(data.bid_increment)).toFixed(2)
    //   this.throwAlert("error", "Please enter a value higher than $" + amount)
    // }
  }

  const setAutobidUI = async(listing_id: string, bids: number, start: number) => {
    // await this.props.autobidOnListing(listing_id, bids, start)
    // await this.props.retrieveBids()
    // await this.props.getListing(listing_id)
  }

  const endAutobidUI = async(listing_id: string) => {
    // await this.props.endAutobid(listing_id)
    // await this.props.retrieveBids()
    // await this.props.getListing(listing_id)
  }

//   handleAutoBidChange(val) {
//     this.setState({"autobidAmount": val})
//   }

//   handleAutoBidStartChange(val) {
//     this.setState({"startAt": val})
//   }  inputSilentListingBid(evt) {
//     let val = evt.target.value
//     this.setState({"bidAmount":val})
//   }

    const logOut = () => {
        AuthService.logout();
        navigate("/login")
    };

    let timer = (!loading ? (id && (<CountDownTimer targetDate={new Date(appState.state.liveListings[id]?.expiry)} /> )) : (<></>))
    let counter = (<div>Please wait...</div>)
    let listingIdView = (id && (<h6>{appState.state.liveListings[id]?.unique_id}</h6>))
    // (id && (<CountDownTimer targetDate={new Date(appState.state.liveListings[id]?.expiry)} />))
    let imageView = (
      !loading ? (
      id && (<div style={{textAlign: "center"}}><img src={appState.state.liveListings[id]?.product.image} style={{objectFit: "contain"}} width="300" height="300"/></div>
      )) : (<div style={{height:"300px", "textAlign":"center"}}><br /><br /><MDBSpinner /></div>)
    )

    let currencyView = (
      canBid ? (
      id && (<span className="animated fadeIn list-prev-c-badge">${(appState.state.liveListings[id]?.currency / 100).toFixed(2)}</span>
      )) : (<span className="animated fadeIn list-prev-c-badge"><MDBSpinner /></span>)
    )

    let titleView = (id && (<h3>{appState.state.liveListings[id]?.title}</h3>))
    let descriptionView = (id && (<p>{appState.state.liveListings[id]?.description}</p>))
    let fundingView = (id && (<p>{appState.state.liveListings[id]?.fundraising_description}</p>))
    let nextAmountView = (<span><strong></strong></span>)
    let silentListingView = (<div></div>)

    let transHistoryView = (!loading ? (
      id && (
        appState.state.liveListings[id]?.transactions?.map((tx) => {
          return (<li key={tx.created.toString()}>
            <span><Moment format="MM-DD-YYYY hh:mma">{tx.created.toString()}</Moment></span><span className="right">{tx.user.username.substring(0, 20)}</span>
          </li>
          )
        })
      )
    ) : (
      <></>
    ))

    let autobidView = (<div></div>)

    let fbMeta = (<></>)
    
    if (listing) {
      const protocol = window && window.location && window.location.protocol;
      var frontEndURL = ''
      
      if (protocol === "https:") {
        frontEndURL = 'https://www.nabify.com'
      } else {
        frontEndURL = 'http://localhost:3000'
      }

      var finalUrl = frontEndURL + '/a/' + id;

      let fbMeta = (
        id && (
          <Helmet>
            <meta property="og:url" content={finalUrl} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={appState.state.liveListings[id]?.product.title} />
            <meta property="og:description" content={appState.state.liveListings[id]?.product.description} />
            <meta property="og:image:secure_url" content={appState.state.liveListings[id]?.product.image} />
          </Helmet>
        )
      )

      let minBidAt = (listing.currency / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
      }); /* $2,500.00 */

      let maxBidAt = (listing.currency).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
      });
    }

    return (
        <>
          <MDBContainer>
          {fbMeta}
          <>
            <style type="text/css">
            {`
                .btn-other {
                    background-color: #13195f;
                    color: white;
                    border-radius: 10px;
                    font-size: 1.2em;
                }

                .btn-other:hover {
                    background-color: #33397f;
                    color: white;
                    border-radius: 10px;
                    font-size: 1.2em;
                }

                .btn-xxl {
                  padding: 1rem 1.5rem;
                  font-size: 1.5rem;
                }
            `}
            </style>
          </>
          <div id="detail">
            <div className="container" id="detail-container">
              <div className="row" id="pwd-container">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-7">
                      <section className="detail-form">
                      <br />
                      {titleView}
                      <hr />
                      {imageView}
                      <hr />
                      <div>
                        <p>Share to: {'        '}
                        {id &&
                          (
                            <>
                              <FacebookShareButton
                                  url={window.location.href}
                                  quote={'From nabify'}
                                >
                                  <FacebookIcon size={48} round />
                              </FacebookShareButton>
                              {'     '}
                              <FacebookMessengerShareButton
                                  appId={'319001158535270'}
                                  url={window.location.href}>
                                <FacebookMessengerIcon size={48} round />
                              </FacebookMessengerShareButton>
                              {'     '}
                              <TwitterShareButton
                                  url={window.location.href}
                                  >
                                  <TwitterIcon size={48} round />
                              </TwitterShareButton>
                              {'     '}
                              <EmailShareButton
                                  subject="Check out this auction from nabify!"
                                  url={window.location.href}
                              >
                                <EmailIcon size={48} round />                              
                              </EmailShareButton>

                            </>
                          )

                        }
                        </p>

                      </div>
                      <br />
                      {/* <FacebookProvider appId="319001158535270">
                        <ShareButton href={fbUrl} className="btn">
                          <img src="https://img.icons8.com/color/96/000000/facebook-new.png" width="32" height="32" />  Share to Facebook
                        </ShareButton>
                      </FacebookProvider> */}
                      </section>
                    </div>
                    <div className="details col-md-5">
                      <div className="details-item-top">
                        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                          <br />
                          <br />
                          <br />
                        </div>
                        <div className="clockdiv">
                          <div>
                            <h5 className="text-center">Auction Ends In</h5>
                            {timer}
                          </div>
                        </div>
                        <br />
                        {id && (<h5><p className="text-center listing-prev-msrp">MSRP: <span >${appState.state.liveListings[id]?.product.msrp}</span></p></h5>)}
                        <br />
                        <h5 className="text-center">Current Bid: {currencyView}</h5>
                        <br />
                        {id &&
                          (
                            (appState.state.liveListings[id]?.is_closed) 
                            ?
                              (
                                <>
                                {(appState.state.liveListings[id]?.bidder) && 
                                (<p className={`text-center ${appState.state.liveListings[id].bidder?.username == appState.state?.username  ? 'text-sea-green' : ''}`}>
                                  <MDBIcon fas icon="user"/>
                                  <span >{'  '}{appState.state.liveListings[id].bidder?.username} has won!</span>
                                </p>)}

                                {(!appState.state.liveListings[id]?.bidder) && 
                                  (<p className={`text-center ${appState.state.liveListings[id].bidder?.username == appState.state?.username  ? 'text-sea-green' : ''}`}>

                                  </p>)}
                                </>
                              )
                            :
                            (
                              (appState.state.liveListings[id]?.bidder) && 
                                (<p className={`text-center ${appState.state.liveListings[id].bidder?.username == appState.state?.username  ? 'text-sea-green' : ''}`}>
                                  <MDBIcon fas icon="user"/>
                                  <span >{'  '}{appState.state.liveListings[id].bidder?.username} is winning!</span>
                                </p>)
                            )
                          )
                        }
                        <br />
                        <div className="text-center">
                        {
                          id &&                  
                           ((!appState.state.liveListings[id]?.is_closed) ? 
                            (
                              <>
                                {AuthService.getCurrentUser() && (id && ((canBid) ? (<Button variant="other" onClick={()=>{bidOnListing(id)}}>Bid Now!</Button>) : (<Button variant="other">Please wait...</Button>)))}
                                {!AuthService.getCurrentUser() && (<Button variant="other" onClick={()=>{navigate('/buybids')}}>Sign In Now!</Button>)}
                              </>
                            ) : 
                            (
                              <Button className="btn-other" disabled>Auction Ended</Button>
                            )
                           )
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                      <h3>Overview</h3>
                      <hr />
                      <MDBCollapse id="descriptionCollapse" show={descriptionOpen}>
                        {descriptionView}
                      </MDBCollapse>
                      <br />
                      <br />
                      <h3>Fundraising Info</h3>
                      <hr />
                      <MDBCollapse id="fundraiseCollapse" show={fundraisingOpen}>
                        {fundingView}
                      </MDBCollapse>
                    </div>
                  </div>
                </div>
                <div className="history-item col-md-4">
                  <br />
                  AUCTION ID: {listingIdView}
                  <br />
                  <div className="detail-item">
                    <h4>Recent Bid History</h4>
                    <ul id="bid_history" className="bid_history">
                      {transHistoryView}
                    </ul>
                    <div>
                      {autobidView}
                    </div>
                    <br />
                  </div>
                  <div className="row">
                    <div className="col-xs-12 d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
                      <h3>Overview</h3>
                      <hr />
                      <MDBCollapse id="descriptionCollapse" show={descriptionOpen}>
                        {descriptionView}
                      </MDBCollapse>
                      <br />
                      <br />
                      <h3>Fundraising Info</h3>
                      <hr />
                      <MDBCollapse id="fundraiseCollapse" show={fundraisingOpen}>
                        {fundingView}
                      </MDBCollapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer

          />
        </MDBContainer>
      <Footer />
      </>
    )
}
export default GenericListing;