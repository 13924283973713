import { AppStateActions, useAppState } from '../../contexts/AppStateContext';
import { MDBContainer, MDBListGroup, MDBListGroupItem } from 'mdbreact'
import { useEffect, useState } from "react";

import AuthService from '../../services/auth.service';
import { Button } from 'react-bootstrap';
import Footer from '../../components/navigation/Footer';
import { MDBIcon } from 'mdb-react-ui-kit';
import PDashHome from '../../components/portal/PDashHome';
import PDashInventory from '../../components/portal/PDashInventory';
import PDashListings from '../../components/portal/PDashListings';
import PDashMembership from '../../components/portal/PDashMembership';
import React from "react";
import UserService from "../../services/user.service";
import { useNavigate } from "react-router-dom";

const PUserDashboard: React.FC = () =>  {

    let initialMode = new URLSearchParams(window.location.search).get("mode");

    const [mode, setMode] = useState(initialMode)

    const navigate = useNavigate()
    const appState = useAppState()

    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            navigate("/login")
        } 


    }, []);

    const performAccountUpgrade = async() => {
        try {
            await UserService.performAccountUpgrade()
            alert("Success! Please logout and log back in to view the admin portal.")
        } catch (e) {
            alert(e)
        }

    }

    const logOut = () => {
        AuthService.logout();
        appState.dispatch({type: AppStateActions.ClearState});
        navigate("/login")
    };    

    let currentView = (<div></div>)
    let checkoutView = (<div></div>)
    
    let overrideMode = (initialMode != mode) ? initialMode : mode

    switch(overrideMode) {
        case "dashboard":
          currentView = (<PDashHome></PDashHome>)
          break
        case "inventory":
          currentView = (<PDashInventory></PDashInventory>)
          break
        case "listings":
          currentView = (<PDashListings></PDashListings>)
          break
        case "membership":
            currentView = (<PDashMembership></PDashMembership>)
            break
        default:
            currentView = (<PDashHome></PDashHome>)
            break
    }

    return (
        <div>
            <div className="container">
                <br />
                <div className="row">
                <div className="col-md-3">
                <div className="panel panel-default sidebar-menu">
                    <div className="panel-heading">
                        <h4>Bidder Info</h4>
                        <p><MDBIcon fas icon='coins'></MDBIcon><span style={{marginLeft: 10}}>{appState.state.username}</span></p>
                    </div>
                    <div className="panel-body">
                    <hr />
                    <h4>Account Information</h4>
                    <div className="verify">
                        <span className="left">{appState.state.email}</span>
                        <br />
                        <span className="right">{appState.state.verified ? 'Verified' : 'Not Verified'}</span>
                        <br/>
                    </div>
                    <hr />
                    {(appState.state.profile?.current_company) && (
                      <>
                        <h4>Organization Information</h4>
                        <div className="verify">
                            <span className="left">{appState.state.profile.current_company.company_name}</span>
                            <br />
                        </div>
                        <hr />
                      </>
                    )}
                    {console.log(appState.state.profile.current_company)}
                    {(appState.state.profile.current_company == null) ? (
                      <>
                        <h4>Organization Information</h4>
                        <div className="verify">
                            <Button className="btn-other" onClick={performAccountUpgrade}>Upgrade Account</Button>
                        </div>
                        <hr />
                      </>
                    ) : (<></>)}
                    <br />
                    <MDBContainer>
                        <MDBListGroup style={{ width: "12rem" }}>
                        <MDBListGroupItem hover onClick={()=>{navigate('/dashboard')}}><span style={{float: 'right'}}><MDBIcon fas icon='columns'></MDBIcon></span>{'  '}Bidder Portal</MDBListGroupItem>
                        </MDBListGroup>
                    </MDBContainer>
                    <br />
                    <MDBContainer>
                        <MDBListGroup style={{ width: "12rem" }}>
                            
                        <MDBListGroupItem hover onClick={()=>{navigate('/portal?mode=dashboard')}}><span style={{float: 'right'}}><MDBIcon fas icon='home'></MDBIcon></span>{'  '}Dashboard</MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{navigate('/portal?mode=inventory')}}><span style={{float: 'right'}}> <MDBIcon fas icon='box-open'></MDBIcon></span>{'  '} Inventory </MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{navigate('/portal?mode=listings')}}><span style={{float: 'right'}}> <MDBIcon fas icon='bars'></MDBIcon></span>{'  '} Listings </MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{navigate('/portal?mode=membership')}}><span style={{float: 'right'}}> <MDBIcon fas icon='users'></MDBIcon></span>{'  '} Membership </MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{setMode('profile')}}><span style={{float: 'right'}}> <MDBIcon fas icon='clipboard'></MDBIcon></span>{'  '} Invoices </MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{setMode('profile')}}><span style={{float: 'right'}}> <MDBIcon fas icon='sitemap'></MDBIcon></span>{'  '} Company Info </MDBListGroupItem>
                        <MDBListGroupItem hover onClick={()=>{logOut()}}><span style={{float: 'right'}}> <MDBIcon fas icon='power-off'></MDBIcon></span>{'  '} Logout </MDBListGroupItem>
                        </MDBListGroup>
                    </MDBContainer>

                    <br />
                    </div>
                </div>
                </div>
                <div className="col-md-9" id="customer-orders">
                    {appState.state.profile?.current_company && (currentView)}
                </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default PUserDashboard;