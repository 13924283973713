import api from "../api";

const handleCatalogUpload = (formData) => {
    
    return api.post("org/template/", formData, {
        "Content-Type": "multipart/form-data"
    })
}

const QuickstartService = {
    handleCatalogUpload,
};
  
export default QuickstartService;
