import React, { Component } from 'react';

import { MDBIcon } from 'mdb-react-ui-kit';

const FooterEnd: React.FC = () =>  {
    // return (
    //     <></>
    // )
    return (
        <div id="footerend" className="p-2" style={{backgroundColor: "#13195f"}}>
            <div style={{color: 'white', textAlign: 'right'}}>nabify 	&copy;2016-2022</div>
        </div>
    );
}

export default FooterEnd;