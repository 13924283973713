import React, { Component, useEffect, useRef, useState } from 'react';

import Footer from '../../components/navigation/Footer';
// import {Helmet} from 'react-helmet';
// import { FacebookProvider, ShareButton } from 'react-facebook';
// import Countdown from 'react-countdown-now';
// import { MDBRangeInput, MDBBtn, MDBCollapse, MDBSpinner, MDBNotification, MDBLink, toast, ToastContainer, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
// import Moment from 'react-moment';
// import moment from 'moment';
// import 'moment-timezone';

const Contact: React.FC = () => {
    return (
    <div className="contact-page">
        <div style={{marginTop: 40, paddingTop: 40}}></div>
        <div className="content">
            <div className="container">
            <div className="col-md-12">
                <div className="box" id="contact">
                <h1>Contact</h1>
                <p className="lead">How can we improve your experience? Please contact us by your most convenient means.</p>
                <hr />
                <div className="row">
                    <div className="col-sm-4">
                    <h3><i className="fa fa-map-marker"></i> Address</h3>
                    <p>229 N Bartlett St<br />
                        Medford, OR. 97501</p>
                    </div>
                    <div className="col-sm-4">
                    <h3><i className="fa fa-phone"></i> Call Center</h3>
                    <p>In Progress. Please use our support e-mail and we will call you back in a timely manner.</p>
                    </div>
                    <div className="col-sm-4">
                    <h3><i className="fa fa-envelope"></i> Electronic Support</h3>
                    <p className="text-muted">Look for the chat icon on the bottom right corner. You can also contact us at the e-mail provided. We will assist you as soon as possible.</p>
                    <ul>
                        <li><strong><a href="mailto:support@nabify.com">support@nabify.com</a></strong></li>
                    </ul>
                    </div>
                </div>
                <hr />
                </div>
            </div>
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default Contact;


    

