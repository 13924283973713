import 'moment-timezone';

import { MDBBtn, MDBIcon, MDBTooltip } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/esm/Button';
import Listing from '../../models/Listing';
import ListingManageService from '../../services/portal/listing-manage.service';
import { MDBDataTableV5 } from 'mdbreact';
import Moment from 'react-moment';
import ProductManageService from '../../services/portal/product-manage.service';
import { useNavigate } from 'react-router';

const PDashListings: React.FC = () => {

  const [listings, setListings] = useState<Listing[]>([])

  useEffect(() => {
    (async() => {
      let { data } = await ListingManageService.getListingList();
      setListings(data.data)
    })()
  },[])


  const navigate = useNavigate()

  const createListing = () => {
    navigate('a/edit/')
  }

  const editListing = (unique_id: string) => {
    navigate('a/edit/?id=' + unique_id)
  }

  const deleteListing = async(unique_id: string) => {
    try {
      if (window.confirm("Are you sure you want to delete this listing?")) {
        const result = await ListingManageService.deleteListing(unique_id)
        let { data } = await ListingManageService.getListingList();
        setListings(data.data)
      }
    } catch (e) {
      alert(e)
    }
  }

  const updateListingWithStatus = async(unique_id: string, status: string) => {
    try {
      const res = await ListingManageService.listingStatusUpdate(unique_id, status)
      alert("Your listing has sucessfully been updated to " + status + ".")
      let { data } = await ListingManageService.getListingList();
      setListings(data.data)
    } catch (e) {
      alert(e)
    }
  }



  let inventoryView = (<div></div>)

  const datatable = {
    columns: [
      {
        label: 'Thumb',
        field: 'image',
        width: 150,
        sort: 'disabled',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Image',
        },
      },
      {
        label: 'Title',
        field: 'title',
        width: 150,
        sort: 'disabled',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Title',
        },
      },
      {
        label: 'Ending Date',
        field: 'expired',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ], rows: 
        listings.map((listing) => {
          return (
            {
                id: listing.title,
                image: <img src={listing.product.image} width="48" height="48" alt={listing.title} title={listing.title} />,
                title: <span onClick={()=>{
                  editListing(listing.unique_id)
                }}>{listing.title}</span>,
                expired: <Moment format="MM-DD-YYYY hh:mma">{listing.expiry}</Moment>,
                status: (<span>{listing.status}</span>),
                action: (
                  <div>
                    {(listing.status == 'unstaged') && (
                      <MDBTooltip tag="span" title="Set Live (sets auction live)">
                        <Button variant='success' className="m-2" size="sm" onClick={()=>{updateListingWithStatus(listing.unique_id,'live')}}>
                          <MDBIcon icon="gavel" size="2x" />
                        </Button>
                      </MDBTooltip>
                    )}
                    {(listing.status == 'live') && (
                      <MDBTooltip tag="span" title="Set Unstaged (removes from live)">
                        <Button variant='warning' className="m-2" size="sm" onClick={()=>{updateListingWithStatus(listing.unique_id,'unstaged')}}>
                          <MDBIcon icon="eye-slash" size="2x"/>
                        </Button>
                      </MDBTooltip>
                    )}
                    <MDBTooltip tag="span" title="Edit Listing">
                      <Button variant='primary' className="m-2" size="sm" onClick={()=>{editListing(listing.unique_id)}}>
                        <MDBIcon icon="edit" size="2x" />
                      </Button>
                    </MDBTooltip>
                    {(listing.status == 'unstaged') && (
                      <MDBTooltip tag="span" title="Delete Listing">
                        <Button variant='danger' className="m-2" size="sm" onClick={()=>{deleteListing(listing.unique_id)}}>
                          <MDBIcon icon="times-circle" size="2x" />
                        </Button>
                      </MDBTooltip>
                    )}
                  </div>
                )
              }
            )
        })
  }

  if (listings?.length > 0) {
    inventoryView = (        
      <MDBDataTableV5
        btn
        searchLabel="Search listing name..."
        responsive
        pagination="true"
        data={datatable}>
      </MDBDataTableV5>
    )
  }

  return (
    <>
      <div className="box">
        <h1>Listings</h1>
        <p className="lead">Below is a list of your listings.</p>
        <Button className="btn-other" onClick={()=>{createListing()}}>Create New Listing</Button>
        {inventoryView}
      </div>
    </>
  )
}

export default PDashListings