import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

import { APICartResponse } from "../../models/Cart";
import AppService from "../../services/app.service";
import AuthService from "../../services/auth.service";
import Button from "react-bootstrap/esm/Button";
import CartService from "../../services/cart.service";
import React from "react";
import UserService from "../../services/user.service";
import { useAppState } from "../../contexts/AppStateContext";
import { useNavigate } from "react-router-dom"

const AccountCart: React.FC = () => {

    const appState = useAppState()
    const [itemCount, setItemCount] = useState(0)

    const navigate = useNavigate()
    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            navigate("/login")
        } else {
          AppService.refreshCart(appState)
        }

    }, []);

    const processDropFromCart = async (unique_id: string) => {
        if (window.confirm("Are you sure you want to remove this item from your cart?")) {
            (async () => {
                try {
                    await CartService.removeItemFromCart(unique_id)
                    await AppService.refreshCart(appState)
                } catch (e: any) {
                    console.log(e)
                }
            })();
        }
    }

    const performValidate = async () => {
        (async () => {
          try {
              await CartService.validateCart()
              navigate("/checkout")
          } catch (e: any) {
              console.log("NO CART")
          }
        })();
    }

    const navVerify = async () => {
      navigate('/verify')
    }
    
    const { cart } = appState.state

    return(
        <div className="box">
        <br />
        <h1>Cart</h1>
        <p className="lead">Check Out Your Winnings!</p>
        <hr />
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Items</th>
                <th>Action</th>
                <th>MSRP</th>
                <th>You Saved</th>
                <th>You Pay!</th>
              </tr>
            </thead>
            <tbody>
                {
                    cart ? 
                    (cart.items?.map((item: any) => {
                        let price = item.price
                        let msrp = item.listing_info.product_info.msrp
                        let saved = msrp - price
                        let savedString = saved.toFixed(2)
                        return (
                          <tr key={item.unique_id}>
                            <td>
                              <img src={item.listing_info.product_info.image} width="24" height="24" />  {item.listing_info.product_info.name}
                            </td>
                            <td>
                              {(item?.procedure) && <Button className="btn-other-sm" style={{marginTop: 0}} onClick={()=>{processDropFromCart(item.unique_id)}}>Remove</Button>}
                              {(!item?.procedure) && <Button className="btn-other-sm" style={{marginTop: 0}} onClick={()=>{processDropFromCart(item.unique_id)}}>Remove</Button>}
                            </td>
                            <td>
                              <span style={{"fontSize": "1.2em"}}>${item.listing_info.product_info.msrp}</span>
                            </td>
                            <td>
                              <span style={{"fontSize": "1.2em"}}>${savedString}</span>
                            </td>
                            <td>
                              <span style={{"fontSize": "1.2em", "fontWeight": "bold", color: "#5cb85c"}}>${item.price}</span>
                            </td>
                          </tr>
                        )
                      })) : 
                      <tr></tr>
                }
              
            </tbody>
          </table>
          <MDBContainer>
            <MDBRow>
                <MDBCol md={8}>
                    <p>*You can share a product that you have won on Facebook/Twitter to get free shipping!</p>
                    <p>**Orders less than $0.50 will incur a credit card processing fee of $0.50</p>
                    <p>***Unpurchased auction items are purged from shopping cart after 30 days.</p>
                </MDBCol>
                <MDBCol md={4} className="text-center">
                    <br />
                    {
                      (appState.state.verified) ?
                      (
                        (cart?.items && cart.items.length > 0) ? 
                        (<><Button className="btn-other" style={{marginTop: 0}} onClick={()=>{performValidate()}}>Continue To Payment</Button></>) : 
                        (<></>)
                      ) : (
                        <>
                          <p>In order to purchase bids and items from nabify, click the button below to verify your account.</p>
                          <Button className="btn-other" onClick={()=>{navVerify()}}>Verify Account</Button>
                        </>
                      )

                    }
                </MDBCol>
            </MDBRow>
          </MDBContainer>


        </div>
      </div>
    )
}

export default AccountCart;
