import Footer from '../../components/navigation/Footer';
import React from 'react'
import { connect } from 'react-redux'

const FAQ = () => {
  return (
    <div style={{marginTop: 40, paddingTop: 20}}>
      <div className="content">
          <div className="container">
              <div className="col-md-9 col-lg-offset-1">
                  <div className="box" id="contact">
                      <h1>Frequently Asked Questions</h1>
                      <p className="lead">Are you curious about something? Do you have some kind of problem with our products?</p>
                      <p>Please feel free to contact us, our customer service center is working for you 24/7.</p>
                      <hr />
                      <div className="panel-group" style={{marginBottom: 5}} id="accordion">
                        <div className="panel panel-primary">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                            		    <a data-toggle="collapse" data-parent="#accordion">
                            			       Under Construction!
                            		    </a>
                                  </h4>
                            </div>
                            <div id="faq" className="panel-collapse collapse">
                                <div className="panel-body">
                                    Copy!
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <Footer />
    </div>
  )
}

export default FAQ;
