import api from "./api";

const uploadOrgCatalogTemplate = (files) => {
  var data = new FormData();
  data.append('file', files[0]);
  return api.post("org/template/", data, { headers: 
      { 
        'Content-Type': 'multipart/form-data'
      }
    })
}

const getMembersOrg = () => {
  return api.get("/manage/membership/")
}

const inviteUserToOrg = (email) => {
  return api.post("/manage/member-invite/", {email})
}

const verifyUserToOrg = (verification_token) => {
  return api.post("/manage/member-verify/", {verification_token})
}

const revokeUserFromOrg = (email) => {
  return api.post("/manage/member-revoke/", {email})
}

const OrgService = {
    getMembersOrg,
    inviteUserToOrg,
    verifyUserToOrg,
    revokeUserFromOrg,
    uploadOrgCatalogTemplate
};
export default OrgService;