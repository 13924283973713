import "./datetime.css";

import DateTimeDisplay from "./DateTimeDisplay";
import { MDBIcon } from "mdb-react-ui-kit";
import useCountDown from "./hooks/useCountdown";

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span style={{fontSize: '0.6em'}}>Auction Ended</span>
    </div>
  );
};

const ShowCounter : React.FC<{ days: number, hours: number, minutes: number, seconds: number }> = ({days, hours, minutes, seconds})=> {
  return (
    <div className="show-counter">
      <div className="countdown-link">
        <MDBIcon fas size="1x" icon="clock"/>{'  '}
        <DateTimeDisplay value={days} type={"D"} isDanger={false} />
        <DateTimeDisplay value={hours} type={"H"} isDanger={false} />
        <DateTimeDisplay value={minutes} type={"M"} isDanger={false} />
        <DateTimeDisplay value={seconds} type={"S"} isDanger={days < 1 && hours < 1 && minutes < 1 && seconds <= 10} />
      </div>
    </div>
  );
};

const CountDownTimer : React.FC<{targetDate: Date}> = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountDownTimer;