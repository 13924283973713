import api from "../api";

const getListing = (unique_id) => {
    return api.get('list/?id='+unique_id);
}

const getListingList = (unique_id) => {
    return api.get('manage/myListings/');
}

const createListing = (listing) => {
    return api.post('manage/listings/', listing)
}

const updateListing = (listing) => {
    return api.put('manage/listings/', listing)
}

const deleteListing = (unique_id) => {
    return api.delete('manage/listings?id='+unique_id,{})
}

const listingStatusUpdate = (unique_id, status) => {
    return api.post('list/status', {unique_id, status})
}


const ListingManageService = {
    getListing,
    getListingList,
    createListing,
    updateListing,
    deleteListing,
    listingStatusUpdate
};

  
export default ListingManageService;
