import * as React from "react";

import { useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import QuickstartService from "../../services/portal/quickstart.service";

// export interface File {
//   name: string;
//   lastModified: number;
//   size: number;
//   type: string;
// }

export interface UploadFileProps {
  onFilesSelected?: (files: File[]) => void;
}

export const FileUploader = (props: UploadFileProps) => {

  const [selectedFiles, setFiles] = useState<File[]>([]);
  const [canUpload, setCanUpload] = useState(false)
  const [uploading, isUploading] = useState(false)

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files || [];
    setFiles(Array.from(files));
    setCanUpload(true)
  };

  const handleFileUpload = async(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    var dataAsset = new FormData()
    if (selectedFiles) {
        isUploading(true)
        dataAsset.append('file', selectedFiles[0])
        dataAsset.append('id', '0')
        let result = await QuickstartService.handleCatalogUpload(dataAsset)
        isUploading(false)
        console.log(result)
        console.log("RESULT")
    }
}

  useEffect(() => {
    if (!props.onFilesSelected) return;
    props.onFilesSelected(selectedFiles);
  }, [selectedFiles, props]);

  return (
    <>
      <input
          type="file"
          accept=".xlsx"
          onChange={handleOnChange}
        />
      <Button className="btn-other" onClick={(e)=>{handleFileUpload(e)}}>Upload Catalog</Button>
    </>

    
  );
};
