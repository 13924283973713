import { MDBInput, MDBListGroupItem, MDBSelect, MDBSelectV5 } from 'mdbreact'
import { useEffect, useState } from "react";

import { MDBBtn, } from 'mdb-react-ui-kit';
import React from "react";
import { useAppState } from '../../contexts/AppStateContext';
import { useNavigate } from "react-router-dom"

const AccountInfo: React.FC = () => {

    const appState = useAppState()

    const states = [
        {"value":"AL","text":"Alabama"},
        {"value":"AK","text":"Alaska"},
        {"value":"AZ","text":"Arizona"},
        {"value":"AR","text":"Arkansas"},
        {"value":"CA","text":"California"},
        {"value":"CO","text":"Colorado"},
        {"value":"CT","text":"Connecticut"},
        {"value":"DE","text":"Delaware"},
        {"value":"DC","text":"District Of Columbia"},
        {"value":"FL","text":"Florida"},
        {"value":"GA","text":"Georgia"},
        {"value":"HI","text":"Hawaii"},
        {"value":"ID","text":"Idaho"},
        {"value":"IL","text":"Illinois"},
        {"value":"IN","text":"Indiana"},
        {"value":"IA","text":"Iowa"},
        {"value":"KS","text":"Kansas"},
        {"value":"KY","text":"Kentucky"},
        {"value":"LA","text":"Louisiana"},
        {"value":"ME","text":"Maine"},
        {"value":"MD","text":"Maryland"},
        {"value":"MA","text":"Massachusetts"},
        {"value":"MI","text":"Michigan"},
        {"value":"MN","text":"Minnesota"},
        {"value":"MS","text":"Mississippi"},
        {"value":"MO","text":"Missouri"},
        {"value":"MT","text":"Montana"},
        {"value":"NE","text":"Nebraska"},
        {"value":"NV","text":"Nevada"},
        {"value":"NH","text":"New Hampshire"},
        {"value":"NJ","text":"New Jersey"},
        {"value":"NM","text":"New Mexico"},
        {"value":"NY","text":"New York"},
        {"value":"NC","text":"North Carolina"},
        {"value":"ND","text":"North Dakota"},
        {"value":"OH","text":"Ohio"},
        {"value":"OK","text":"Oklahoma"},
        {"value":"OR","text":"Oregon"},
        {"value":"PA","text":"Pennsylvania"},
        {"value":"RI","text":"Rhode Island"},
        {"value":"SC","text":"South Carolina"},
        {"value":"SD","text":"South Dakota"},
        {"value":"TN","text":"Tennessee"},
        {"value":"TX","text":"Texas"},
        {"value":"UT","text":"Utah"},
        {"value":"VT","text":"Vermont"},
        {"value":"VA","text":"Virginia"},
        {"value":"WA","text":"Washington"},
        {"value":"WV","text":"West Virginia"},
        {"value":"WI","text":"Wisconsin"},
        {"value":"WY","text":"Wyoming"}
    ]
    const [options, setOptions] = useState(states)
    return(
    <>
      <br />
      <div className="box">
          <h1>Account Info</h1>
          <hr />
          {
            !appState.state.verified ? 
            (
              <>
                <h3>Verify Account</h3>
                <h5>Your account has not been verified yet.</h5>
              </>

            ):
            (
              <></>
            )
          }
          <hr />
          <h3>Shipping</h3>
          <h5>Update your billing and shipping info.</h5>
          <form name="loginForm" id="infoForm">
            <div className="row">
              <div className="col-sm-5">
                <label htmlFor="ownersname">First</label>
                <input type="text" className="form-control" aria-label="firstname" required/>
              </div>
              <div className="col-sm-5">
                <label htmlFor="ownersname">Last</label>
                <input type="text" className="form-control" aria-label="midname" required/>
              </div>
              <div className="col-sm-2">
                <label htmlFor="ownersname">M.I.</label>
                <input type="text" className="form-control" aria-label="lastname" required/>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-8">
                  <label htmlFor="address">Address</label>
                  <input type="text" className="form-control" aria-label="address" placeholder="ie.123 Robinson St." required/>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="address2">Apt Suite</label>
                  <input type="text" className="form-control" aria-label="address" placeholder="Apt/Suite" required/>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-4">
                  <label htmlFor="city">Location (ZIP, State, City)</label>
                  <input type="text" name="city" className="form-control" placeholder="City" />
                </div>
                <div className="col-sm-4">
                <label htmlFor="city">State</label>
                  <select className="browser-default custom-select">
                    <option></option>
                    { states ? 
                      states.map((st) => {
                        return (<option key={st.value} value={st.value}>{st.text}</option>)
                      }) :
                      <></>
                    }
                    <option value=""></option>
                  </select>
                </div>
                <div className="col-sm-4">
                  <label htmlFor="zip">ZIP Code</label>
                  <input type="text" name="zipcode" className="form-control" placeholder="Zipcode" />
                </div>
              </div>
            </div>
            <div className="text-center">
              <MDBBtn>Update</MDBBtn>
            </div>
          </form>
        <div id="placeholdercityinput">
        </div>
        <div id="placeholderstateinput">
        </div>
      </div>
        
    </>)
}

export default AccountInfo;