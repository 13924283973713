import 'bootstrap/dist/css/bootstrap.min.css';

import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"

import { APIPromocodeResponse } from '../models/Promocode';
import AppService from '../services/app.service';
import AuthService from "../services/auth.service";
import BidpackService from "../services/bidpack.service";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Footer from "../components/navigation/Footer";
import React from "react";
import SocketContext from "../contexts/SocketContext";
import UserService from '../services/user.service';
import { uniqueId } from 'lodash';
import { useAppState } from '../contexts/AppStateContext';

type Bidpacks = {
    color: string;
    image_url: string;
    name: string;
    price: string;
    procedure: string;
    quantity: number;
    rating: number;
    unique_id: string;
};
  
const BuyBids: React.FC = () => {

    const [promo, setPromo] = useState("")
    const [bidPacks, setBidPacks] = useState<Bidpacks[]>([]);

    const navigate = useNavigate()
    const appState = useAppState()
    const pathname = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
        (async () => {

            try {
                let { data } = await BidpackService.getBidpacks();
                setBidPacks( data.data )
                // console.log( data.data )
            } catch (e) {

            }
        })()
    }, [pathname])

    const usePromo = async () => {
        try {
            console.log(promo)
            let data : APIPromocodeResponse = await BidpackService.postPromocode(promo);
            if (data.data.status == "OK") {
                alert(data.data.data)
                await AppService.refreshBids(appState)
            } else {
                alert(data.data.data)
            }

            
        } catch (e) {
            alert("Cannot use promocode.")
        }
    };

    const addPackToCart = async (unique_id: string) => {
        try {
            await BidpackService.postBidpackToCart(unique_id);
            await AppService.refreshCart(appState)
            navigate('/dashboard')
        } catch (e) {
            alert("You will need to log in to buy bids. Please click OK to continue.")
            navigate('/login')
        }
    };

    return (
        <div>
            <br />
            <br />
            <section className="text-center my-3">
                <div className="row">
                    <div className="col-md-6">
                    <h1 className="h1-responsive font-weight-bold text-right ">
                        Bid Packs
                    </h1>
                    <h4 className="grey-text text-right h4-responsive ">
                        Buy More &amp; Save More!
                    </h4>
                    </div>
                    <div className="col-md-4">
                    <h4 className="grey-text text-left h4-responsive ">
                        Have a promocode? You can redeem it here.
                    </h4>
                    <span className="input-group-text" id="basic-addon">
                        {/* <input type="text" placeholder="Redeem Here" className="form-control" size="15" maxLength="15" onChange={this.updateBidPackCode} style={{textAlign: "center"}} value={this.state.code} /> */}
                        <input type="text" placeholder="Redeem Here" className="form-control" pattern="[A-za-z0-9]" maxLength={30} onChange={(e)=>{setPromo(e.target.value)}}/>
                        <button type="button" className="btn btn-primary-extended" onClick={usePromo}>Redeem</button>
                    </span>
                    </div>
                </div>
                <br />

                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                    {
                        bidPacks.map((b) => {
                            return (
                                <Card key={b.unique_id} style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={b.image_url} />
                                <Card.Body>
                                    <Card.Title>{b.name}</Card.Title>
                                    <br />
                                    <Card.Title>${b.price}</Card.Title>
                                    <br />
                                    <Card.Title><span className="animated fadeIn list-prev-c-badge">{b.quantity} bids</span></Card.Title>
                                    <br />
                                    <Button variant="other" onClick={()=>{addPackToCart(b.unique_id)}}>Buy Now!</Button>
                                </Card.Body>
                            </Card>
                            )
                        })
                    }

                </div>
                </section>
                <section className="text-center my-5">
                <h2 className="text-center"> How does a nabify auction work? </h2>
                <div className="row">
                    <div className="col-md-9">
                    <br />
                    <ul style={{textAlign: "left", marginLeft: "20px"}}>
                        <li>Each bid raises the deal price by $0.01.</li>
                        <li>The auction clock restarts from 10 seconds every time someone bids.</li>
                        <li>Last bidder when clock runs out, wins the deal!</li>
                    </ul>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default BuyBids


// const mapStateToProps = (state) => ({
//   errors: authErrors(state),
//   bidpacks: getBuyBidpacks(state)
// })
// const mapDispatchToProps = (dispatch) => ({
//   onSubmit: (username, password) => {
//     return dispatch(login(username, password))
//   },
//   statusCheck: () => {
//     return dispatch(echo())
//   },
//   retrieveBidpacks: () => {
//     return dispatch(getBidpacks())
//   },
//   addBidpackToCart: (unique_id) => {
//     return dispatch(postBidpackToCart(unique_id))
//   },
//   addPromocode: (code) => {
//     return dispatch(postPromocode(code))
//   }
// })
