import 'moment-timezone';

import { MDBBtn, MDBIcon, MDBTooltip } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/esm/Button';
import ListingManageService from '../../services/portal/listing-manage.service';
import { MDBDataTableV5 } from 'mdbreact';
import Memberlist from '../../models/Memberlist';
import Moment from 'react-moment';
import OrgService from '../../services/org.service';
import ProductManageService from '../../services/portal/product-manage.service';
import { useNavigate } from 'react-router';

const PDashMembership: React.FC = () => {

  const [memberships, setMemberships] = useState<Memberlist[]>([])

  useEffect(() => {
    (async() => {
      let { data } = await OrgService.getMembersOrg()
      setMemberships(data.data)
    })()
  },[])
  
  const inviteUser = async(email: string) => {
    try {
        let result = await OrgService.inviteUserToOrg(email)

    } catch (e) {

    }
  }

  const revokeUser = async(email: string) => {
    try {
        let result = await OrgService.revokeUserFromOrg(email)

    } catch (e) {

    }
  }

  let membershipsView = <></>;

  const datatable = {
    columns: [
      {
        label: 'E-Mail',
        field: 'email_alias',
        width: 150,
        sort: 'disabled',
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Title',
        },
        },
      {
        label: 'Invited Date',
        field: 'created',
        width: 150,
        sort: 'disabled',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Title',
        },
      },
      {
        label: 'Status',
        field: 'status',
        width: 150,
        sort: 'disabled',
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ], rows: 
        memberships.map((membership) => {
          return (
            {
                email_alias: (<span>{membership.email_alias}</span>),
                updated: <Moment format="MM-DD-YYYY hh:mma">{membership.updated}</Moment>,
                status: (<span>{membership.verified}</span>),
                action: (<button>HEY</button>)
            }
        )
    })
  }

  if (memberships?.length > 0) {
    membershipsView = (        
      <MDBDataTableV5
        btn
        searchLabel="Search listing name..."
        responsive
        pagination="true"
        data={datatable}>
      </MDBDataTableV5>
    )
  }

  return (
    <>
      <div className="box">
        <h1>Membership</h1>
        <p className="lead">All users can be found from membership.</p>
        <Button className="btn-other" onClick={()=>{inviteUser('')}}>Invite User</Button>
        <div>
            <div className="container">
                <div className="row">
                    {membershipsView}
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default PDashMembership