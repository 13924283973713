import React, { createContext, useReducer } from "react"

import Listing from "../models/Listing"

const _ = require('lodash')

export const AppStateActions = {
  SetProfile:"setProfile",
  SetBids:"setBids",
  SetUsername:"setUsername",
  SetEmail:"setEmail",
  SetVerified:"setVerified",
  SetUniqueId:"setUniqueId",
  SetCart:"setCart",
  ClearState:"clearState",
  UpdateListing:"updateListing",
  UpdateMultipleListings:"updateMultipleListings",
  UpdateAllListings:"updateAllListings",
}

type Action = 
  {type: string, value?: any}

type Dispatch = (action: Action) => void
type AppStates = {bids?: number, 
  username?: string, 
  email?: string,
  verified?: boolean,
  unique_id?: string, 
  cart?: any, 
  liveListings: {[key: string]: Listing} , 
  profile?: any,
  orderedListings: Listing[]
}
type AppStateProps = {children: React.ReactNode}

let defaultState = {bids: 0, username: '', email: '', verified: false, unique_id: '', cart: {}, liveListings: {}, profile: {}, orderedListings: []}

const AppStateContext = createContext<
  {state: AppStates; dispatch: Dispatch} | undefined
>(undefined)

const transformListing = (states: AppStates, listing: Listing) => {
  states.liveListings[listing.unique_id] = listing 
  return states
}

const transformListings = (states: AppStates, listings: {[key: string]: Listing}) => {
  let keys = Object.keys(listings)
  let workingState = states.liveListings
  keys.forEach((key) => {
    // console.log(workingState[key], listings[key])
    try {
      workingState[key].currency = listings[key].currency
      workingState[key].bidder = listings[key].bidder
      workingState[key].expiry = listings[key].expiry
      workingState[key].status = listings[key].status
      workingState[key].is_closed = listings[key].is_closed
      workingState[key].is_critical = listings[key].is_critical
      workingState[key].transactions = listings[key].transactions
    } catch (e) {
      console.log(e)
      console.log("NOT HERE")
    }
  })

  states.liveListings = workingState
  return states
}

const orderListingsByExpiry = (listings: {[key: string]: Listing}) => {
  const list = _.sortBy(Object.values(listings), ['-created']);
  return list
}

const transformAllListings = (states: AppStates, listings: Listing[]) => {  
  listings.forEach((listing: Listing) => {
    states.liveListings[listing.unique_id] = listing  
  })

  return states
}


function appStateReducer(state: AppStates, action: Action): AppStates {
  switch (action.type) {
    case AppStateActions.SetProfile:
        return {...state, profile: action.value}
    case AppStateActions.SetBids:
        return { ...state, bids: action.value ?? 0 }
    case AppStateActions.SetUsername:
        return { ...state, username: action.value ?? '' }
    case AppStateActions.SetEmail:
        return { ...state, email: action.value ?? '' }
    case AppStateActions.SetVerified:
        return { ...state, verified: action.value ?? false }
    case AppStateActions.SetUniqueId:
        return { ...state, unique_id: action.value ?? '' }      
    case AppStateActions.SetCart:
        return { ...state, cart: action.value }
    case AppStateActions.UpdateListing:
        const transformInvidual = transformListing(state, action.value)
        return { ...state, liveListings: transformInvidual.liveListings, orderedListings: orderListingsByExpiry(state.liveListings) }
    case AppStateActions.UpdateMultipleListings:
        const resultListings = transformListings(state, action.value)
        return { ...state, liveListings: resultListings.liveListings, orderedListings: orderListingsByExpiry(state.liveListings) }
    case AppStateActions.UpdateAllListings:
        const transformed = transformAllListings(state, action.value )
        return { ...state, liveListings: transformed.liveListings, orderedListings: orderListingsByExpiry(state.liveListings) }
    case AppStateActions.ClearState:
        return defaultState
    default: {
      throw new Error(`No action type for ${action.type} `)
    }
  }
}

function AppStateProvider({children}: AppStateProps) {
  const [state, dispatch] = useReducer(appStateReducer, defaultState)
  const value = {state,dispatch}
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  )
}

function useAppState() {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppStateProvider')
  }
  return context
}

export {AppStateProvider, useAppState}
