import { MDBBtn, MDBIcon, MDBInput, MDBTextArea } from 'mdb-react-ui-kit';
import React, { Component, useEffect, useState } from 'react';

import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/navigation/Footer';
import Form from 'react-bootstrap/esm/Form';
import ListingManageService from '../../services/portal/listing-manage.service';
import ProductManageService from '../../services/portal/product-manage.service';
import { useNavigate } from 'react-router';

const PProductEdit: React.FC = () =>  {

    const thisId = new URLSearchParams(window.location.search).get(
        "id"
    );

    const categoryOptions = [
        { text: "Accessories",value: "acc" },
        { text: "Apparel",value: "app" },
        { text: "Art & Photography",value: "art" },
        { text: "Automotive",value: "aut" },
        { text: "Books",value: "bks" },
        { text: "Children & Infants",value: "cis" },
        { text: "Electronics (Accessories)",value: "eac" },
        { text: "Electronics (Consumeries)",value: "eco" },
        { text: "Health & Beauty",value: "hbu" },
        { text: "Home & Garden",value: "hgd" },
        { text: "Movies, Music, & Video Games",value: "mmv" },
        { text: "Pets",value: "pet" },
        { text: "Speciality",value: "spc" },
        { text: "Sports & Outdoors",value: "sao" },
        { text: "Toys & Games",value: "tag" },
        { text: "Gift Cards & Collectibles",value: "gcc" },
        { text: "Dining",value: "din" },
        { text: "Bid Packs",value: "bpk" }
    ]

    const [selectedCategory, setSelectedCategory] = useState('Select a category')
    const [step, setStep] = useState(1)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [msrp, setMSRP] = useState(0)
    const [paid, setPaid] = useState(0)
    const [sku, setSKU] = useState('-')
    const [upccode, setUPCCode] = useState('-')
    const [image, setImage] = useState('https://s3-us-west-2.amazonaws.com/fast-content/img/nabify_48.png')
    const [imageToUpload, setImageToUpload] = useState<FileList>()
    const [uploading, setUploading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (thisId) {
            (async() => {
                let oldProduct = await ProductManageService.getProduct(thisId)
                let { data } = oldProduct.data
                setName(data.name)
                setDescription(data.description)
                setMSRP(data.msrp)
                setPaid(data.paid)
                setSKU(data.sku)
                setImage(data.image)
                setSelectedCategory(data.category.endpoint)
                setStep(4)
            })()
        }
    },[])

    const handleNameChange = (e: any) => {
        setName(e.target?.value)
        if (e.target?.value != '') {
            setStep(2)
        }
    }

    const handleCategorySelect = (e: any) => {
        setSelectedCategory(e.target?.value)
        if (e.target?.value != 'Select a category') {
            setStep(4)
        }
    }

    const handleDescChange = (e: any) => {
        setDescription(e.target?.value)

    }

    const handleMSRPChange = (e: any) => {
        setMSRP(e.target?.value)
    }

    const handlePaidChange = (e: any) => {
        setPaid(e.target?.value)
    }

    const handleUPCChange = (e: any) => {
        setUPCCode(e.target?.value)
    }

    const handleSKUChange = (e: any) => {
        setSKU(e.target?.value)
    }

    const handleSetImage = (e: FileList | null) => {
        const files = e;
        if (files) {
            setImageToUpload(files)
        }
        else {
            alert("Please select a file to upload form your device.")
        }
    }

    const handleImageURLChange = (e: any) => {
        setImage(e)
    }

    const handleFileUpload = async(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        var dataAsset = new FormData()
        if (imageToUpload) {
            setUploading(true)
            dataAsset.append('file',imageToUpload[0])
            dataAsset.append('id', '0')
            let imagePreview = await ProductManageService.createAsset(dataAsset)
            setImage(imagePreview.data?.data)
            setUploading(false)
        }
    }


    const categoriesView = (
        <Form.Group controlId="formProductSelect">
            <Form.Label>Select Category</Form.Label>
            <Form.Control
                as="select"
                value={selectedCategory}
                onChange={e => {
                    handleCategorySelect(e);
                }}>
                {
                    categoryOptions.map((c) => {
                        let id = "radio_"+c.value
                        return (<option key={c.value} id={id} value={c.value}>{c.text}</option>)
                    })
                }
            </Form.Control>
        </Form.Group>
    )

    const submit = async () => {
        let product = {
            name,
            description,
            title: name,
            msrp,
            paid,
            sku: sku,
            upccode: (upccode == '=' ? upccode : 'None'),
            image,
            category_stub: selectedCategory,
            unique_id: ''
        }
    
        if (thisId) {
            try {
                product['unique_id'] = thisId
                await ProductManageService.updateProduct(product)
                alert("Product updated! You will be redirected back to the portal.")
                navigate('/portal?mode=inventory')
            } catch (e) {
                alert("Please check all fields again.")
                // alert(e.data?.data)
            }
        } else {
            try {
                await ProductManageService.createProduct(product)
                alert("Product created! You will be redirected back to the portal.")
                navigate('/portal?mode=inventory')
            } catch (e) {
                alert("Please check all fields again.")
                // alert(e.data?.data)
            }
        }       
    }



    const step2View = (
        <>
            <div style={{'zIndex':-2}} className="form-group has-success has-feedback animated fadeInLeft">
                {categoriesView}
            </div>
        </>
    )

    const step3View = (
        <>
            <div style={{'zIndex':-2}} className="animated fadeInLeft">
            <div className="container">
                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                    <label htmlFor="msrp">MSRP</label>
                    <p className="text-muted">The original sale price of the product.</p>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">$</span>
                        <input value={msrp} type="number" onChange={handleMSRPChange} className="form-control" placeholder="0.00" aria-label="msrp" aria-describedby="product-msrp" />
                    </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                    <label htmlFor="msrp">Paid</label>
                    <p className="text-muted">How much we paid for this product.</p>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">$</span>
                        <input value={paid} type="number" onChange={handlePaidChange} className="form-control" placeholder="0.00" aria-label="msrp" aria-describedby="product-paid"/>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="form-group">
                <div className="col-xs-7">
                <br />
                <label htmlFor="upc">UPC (optional)</label>
                <p className="text-muted">If the product comes with a UPC, EAN-13, etc. code.</p>
                <input value={upccode} onChange={handleUPCChange} type="text" className="form-control"/>
                </div>
            </div>
            <div className="form-group">
                <div className="col-xs-7">
                <br />
                <label htmlFor="sku">SKU (optional)</label>
                <p className="text-muted">Matching inventory number in org inventory.</p>
                <input value={sku} onChange={handleSKUChange} type="text" className="form-control"/>
                </div>
            </div>
            </div>
        </>
    )

    const step4View = (
        <>
            <div className="row">
                <div style={{'zIndex':1}} className="col-sm-9 box">
                <div className="form-group">
                    <h2><div className="numberCircle">2</div>Images</h2>
                    <p className="lead">You can upload product images here.</p>
                    <hr />
                </div>
                <div className="form-group">
                    <h4>Main Image</h4>
                    <div className="row">
                    <div className="col-sm-6 images-center">
                        <p>Image</p>
                        <div className="upload-area">
                        <div className="alert alert-success text-center">
                            <h5>
                            <strong id="status">
                                Status: Waiting
                            </strong>
                            </h5>
                            <span>Click or Drag a File Here to Upload</span>
                            <input onChange={(e)=> {handleSetImage(e.target?.files)}} type="file" name="file" id="myFile" accept="image/png, image/jpeg" multiple />
                            {(!uploading) && <button className="btn coolgreen" onClick={handleFileUpload}>Upload</button>}
                            {(uploading) && <button className="btn coolgreen">Uploading...</button>}
                        </div>
                        <p>
                            Format: PNG, GIF, JPG, less than 200KB.
                        </p>
                        <h4> Instructions</h4>
                        <ol>
                            <li>Click or drag a file to add into the box.</li>
                            <li>When a file has been chosen, click Upload Image.</li>
                            <li>If successful, the image will appear in the box above.</li>
                        </ol>
                        </div>
                    </div>
                    <div className="col-sm-6 images-center">
                        <p>Image</p>
                        <img src={image} width="144px" height="144px" />
                        <br />
                        <br />
                        <p>Image URL</p>
                        <input type="text" value={image} className="form-control" onChange={(e) => {handleImageURLChange(e.target.value)}} required/>
                    </div>
                    </div>
                </div>
                <br />
                </div>
                <hr />
            </div>
        </>
    )

    // let imageView = (<img src="https://s3-us-west-2.amazonaws.com/fast-content/img/nabify_48.png" width="144px" height="144px" />)
    // let imageUrl = (<input onChange={this.handleEmailInputChange} type="text" value="https://s3-us-west-2.amazonaws.com/fast-content/img/nabify_48.png" className="form-control" required/>)
    // let registerButton = (<div><p>There are still fields that need to be added.</p></div>)
    // if (product.image) {
    //   imageView = (
    //     <img src={product.image} width="144px" height="144px" />
    //   )
    //   imageUrl = (
    //     <input type="text" value={product.image} className="form-control"  required/>
    //   )
    // }

    return (
        <>
        <div id="all">
            <br />
            <br />
            <div id="content">
            <div className="container">
                <div className="col-sm-12">
                    <div id="content">
                    <div className="row">
                        <div className="col-sm-9 box">
                        <div className="form-group">
                            <h2><div className="numberCircle">1</div>Product Info</h2>
                            <p className="lead">Information for the product.</p>
                            <hr />
                            <div className="form-group has-success has-feedback">
                                <p>Product Name</p>
                                <MDBInput placeholder="Enter your product name here." value={name} onChange={handleNameChange}/>
                            </div>
                            <br />
                            <div className="form-group has-success has-feedback">
                                <p>Product Description</p>
                                <MDBTextArea
                                    placeholder="Enter a brief description of your product."
                                    rows={5}
                                    onChange={handleDescChange}
                                    value={description}
                                    >

                                </MDBTextArea>
                            </div>
                            <br />
                            {(step > 1) && step2View}
                            <br />
                            {(step > 2) && step3View}
                            <br />
                        </div>
                        </div>
                        <div className="col-sm-3 tip">
                        <div className="tip-header">
                        <h4>Guidelines</h4>
                        </div>
                        <div className="tip-body">
                        <p className="copy"><b>Product Name</b></p>
                        <p className="copy">Min 20 characters, Max 100 characters</p>
                        </div>
                        <div className="tip-body">
                        <p className="copy"><b>Product Description (Old)</b></p>
                        <p className="copy">Min 50 characters, Max 500 characters</p>
                        </div>
                        <div className="tip-body">
                        <p className="copy"><b>Product Description (New)</b></p>
                        <p className="copy">This is a Rich-Text Format editor. </p>
                        </div>
                        <div className="tip-body">
                        <p className="copy"><b>MSRP</b></p>
                        <p className="copy">Format: <b>1399.99</b>, no dollar signs, commas</p>
                        </div>
                        <div className="tip-body">
                        <p className="copy"><b>Paid</b></p>
                        <p className="copy">Format: <b>1399.99</b>, no dollar signs, commas</p>
                        </div>
                        <div className="tip-body">
                        <p className="copy"><b>SKU</b></p>
                        <p className="copy">Format: 7-10 characters, capitals and numbers</p>
                        </div>
                        <div className="tip-body">
                        <p className="copy"><b>Features</b></p>
                        <p className="copy">Maximum 10 features separated by pipes "|"</p>
                        </div>
                    </div>
                    </div>
                    {(step > 3) && step4View}
                </div>

                <div className="row">
                    <div className="col-sm-9 box">
                    <div className="form-group">
                        <h2><div className="numberCircle">3</div>Confirmation</h2>
                        <p className="lead">Please confirm any edits or additions to this product.</p>
                        <hr />
                    </div>
                    <div className="text-center">
                        <Button className="md-raised md-primary" aria-label="register" onClick={()=>navigate('/portal')}>Cancel</Button>
                        {(step > 2) && (<Button className="md-raised md-primary" onClick={()=>{submit()}} aria-label="register">Register</Button>)}
                        {(step < 3) && (<div><p>There are still fields that need to be added.</p></div>)}
                    </div>
                    </div>
                </div>
                </div>
                <div id="myModal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Terms and Conditions</h4>
                    </div>
                    <div className="modal-body">
                        <p>Add Terms and Conditions</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>
                <hr />
            </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default PProductEdit;