import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component, useEffect, useState } from 'react';

import AuthService from '../services/auth.service';
import Button from 'react-bootstrap/esm/Button';
import { useAppState } from '../contexts/AppStateContext';
import { useNavigate } from 'react-router';

const Important: React.FC = () =>  {

    const [status, setStatus] = useState('')
    const [message, setMessage] = useState('') 
    const [actionText, setActionText] = useState('')


    const appState = useAppState()
    const navigate = useNavigate()

    useEffect(() => {
        if (AuthService.getCurrentUser()) {
            if (appState.state.bids && appState.state.bids > 0) {
                setStatus('hasBids')
                setMessage('Start Bidding!')
                setActionText('-')
            }
            else if (appState.state.bids && appState.state.bids < 1) {
                setStatus('noBids')
                setMessage('Out of bids?')
                setActionText('Buy Bids!')
            }
        } else {
            setStatus('loggedIn')
            setMessage('')
            setActionText('Get Started!')
        }
    }, [appState])

    const handleClick = () => {
        if (status == 'hasBids') {
            navigate('/buybids')
        }
        if (status == 'noBids') {
            navigate('/buybids')
            }
        if (status == 'loggedIn') {
            navigate('/login')
        }
    }

    return (
        <>
            {/* <div className='shadow-sm p-3 mb-5 bg-white rounded'>
                <div style={{textAlign: 'center'}}>
                    {(status != 'hasBids') && (<p>{message} <Button onClick={handleClick} style={{borderRadius:'10px'}} className="btn-other" size="lg">{actionText}</Button></p>)}
                </div>
            </div> */}
        </>
    );
}

export default Important;