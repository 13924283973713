import 'moment-timezone';

import { MDBBtn, MDBIcon, MDBTooltip } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { MDBDataTableV5 } from 'mdbreact';
import Moment from 'react-moment';
import Product from '../../models/Products';
import ProductManageService from '../../services/portal/product-manage.service';
import { useNavigate } from 'react-router';

const PDashInventory: React.FC = () => {

  const [productList, setProductList] = useState<Product[]>([])

  useEffect(() => {
    (async() => {
      await refreshContext()
    })()
  },[])

  const navigate = useNavigate()

  const createProduct = () => {
    navigate('p/edit/')
  }

  const editProduct = (unique_id: string) => {
    navigate('p/edit/?id=' + unique_id)
  }

  const deleteProduct = async(unique_id: string) => {
    try {
      if (window.confirm("Are you sure you want to delete this product?")) {
        const result = await ProductManageService.deleteProduct(unique_id)
        await refreshContext()
      }
    } catch (e) {

    }
  }

  const refreshContext = async () => {
    let { data } = await ProductManageService.getProductList();
    setProductList(data.data)
  }

  let inventoryView = (<div></div>)

  const datatable = {
    columns: [
      {
        label: 'Thumb',
        field: 'image',
        width: 150,
        sort: 'disabled',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Image',
        },
      },
      {
        label: 'Name',
        field: 'name',
        width: 150,
        sort: 'disabled',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Date Created',
        field: 'created',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Date Updated',
        field: 'updated',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ], rows: 
        productList.map((product, index) => {
          return (
            {
                id: product.name,
                image: <img src={product.image} width="48" height="48" alt={product.name} title={product.name} />,
                name: <p onClick={()=>{
                  editProduct(product.unique_id)
                }}>{product.name}</p>,
                created: <Moment format="MM-DD-YYYY hh:mma">{product.created}</Moment>,
                updated: <Moment format="MM-DD-YYYY hh:mma">{product.updated}</Moment>,

                action: (
                  <div>
                    <MDBTooltip tag="span" title="Create Auction From Product">
                      <Button variant='info' className="m-2" size="sm" onClick={()=>{navigate('/portal/a/edit?pid=' + product.unique_id)}}>
                        <MDBIcon icon="plus-circle" size="2x" />
                      </Button>
                    </MDBTooltip>
                    <MDBTooltip tag="span" title="Edit Product">
                      <Button variant='primary' className="m-2" size="sm" onClick={()=>{editProduct(product.unique_id)}}>
                        <MDBIcon icon="edit" size="2x" />
                      </Button>
                    </MDBTooltip>
                    <MDBTooltip tag="span" title="Delete Product">
                      <Button variant='warning' className="m-2" size="sm" onClick={()=>{deleteProduct(product.unique_id)}}>
                        <MDBIcon icon="times-circle" size="2x" />
                      </Button>
                    </MDBTooltip>
                  </div>
                )
              }
            )
        })
  }

  if (productList?.length > 0) {
    inventoryView = (        
      <MDBDataTableV5
        btn
        searchLabel="Search product name..."
        responsive
        pagination="true"
        data={datatable}>
      </MDBDataTableV5>
    )
  }

  return (
    <>
      <div className="box">
        <h1>Inventory</h1>
        <p className="lead">Here are a list of your products.</p>
        <Button className="btn-other" onClick={()=>{createProduct()}}>Create New Product</Button>
        {inventoryView}
      </div>
    </>
  )
}

export default PDashInventory

