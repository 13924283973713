import {
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler
} from "mdb-react-ui-kit";
import { memo, useEffect, useState } from "react";

import AppService from "../../services/app.service";
import AuthService from "../../services/auth.service";
import { Button } from "react-bootstrap";
import FacebookLogin from 'react-facebook-login';
import React from "react";
import { useAppState } from "../../contexts/AppStateContext";
import { useNavigate } from "react-router-dom";

const Navigation: React.FC = () => {

  const [showModal, setShowModal] = useState(false);
  const [showNavColorThird, setShowNavColorThird] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const navigate = useNavigate();
  const appState = useAppState();

  useEffect(() => {

    (async () => {
      AppService.refreshAppState(appState)
    })()
    
    document.addEventListener("click", toggleModal);
  }, []);

  const navHome = () => {
    navigate("/home");
  };

  const responseFacebook = async (response: any) => {
    try {
      await AuthService.loginFB(response)
      await AppService.refreshAppState(appState)
      setShowLoginModal(false)
      navigate("/verify")
    } catch (e) {
      alert("There was an error while logging you into Facebook. Please check your credentials and try again.")
    }
  }


  const navDashboard = () => {
    if (AuthService.getCurrentUser()) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }

  const navVerify = () => {
    if (AuthService.getCurrentUser()) {
      navigate("/verify");
    } else {
      navigate("/login");
    }
  }

  const navBids = () => {
    navigate('/buybids')
  }

  const logOut = () => {
    AuthService.logout();
    navigate("/login");
  };

  // function handleClick() {
  //   setShowModal(!showModal);
  // }

  function toggleModal(e: any) {
    let bellButton = document.getElementById("bell-icon");
    if (
      e.target !== bellButton &&
      e.target.className !== "notifications" &&
      e.target.className !== "group-header-new" &&
      e.target.className !== "group-header-earlier"
    ) {
      setShowModal(false);
    }
  }

  function toggleLoginModal(e: any) {
    setShowLoginModal(!showLoginModal)
  }

  function navigateToLogin() {
    navigate("/login")
    setShowLoginModal(false)
  }

  return (
    <>
      <MDBNavbar sticky expand='sm' light style={{ backgroundColor: '#fff' }}>
        <MDBContainer fluid>
          <MDBNavbarBrand>
              <img onClick={navHome} src="https://fast-content.s3.us-west-2.amazonaws.com/images/nabifylogo.png" height="36" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarColor02'
            aria-controls='navbarColor02'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavColorThird(!showNavColorThird)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColorThird} navbar>
            <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'> 
              <MDBNavbarItem>
                  <MDBNavbarLink onClick={()=> {navigate('/partners')}}>
                    Partners
                  </MDBNavbarLink>
                </MDBNavbarItem>
            </MDBNavbarNav>   
            <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'> 
              <MDBNavbarItem>
                <MDBNavbarLink onClick={()=> {navigate('/how-it-works')}}>
                  How
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav> 
            <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'> 
              <MDBNavbarItem>
                <MDBNavbarLink onClick={()=> {navigate('/how-it-works')}}>
                  Why
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>   
            <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'> 
              <MDBNavbarItem>
                <MDBNavbarLink onClick={()=> {navigate('/profile?uid='+appState.state.unique_id)}}>
                  {(AuthService.getCurrentUser() && appState.state.unique_id !== '') && (
                    <span>
                      Donor Profile
                    </span>)
                  }
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>             
            <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>  
              {/* verify */}
              <MDBNavbarItem>
                <MDBNavbarLink>
                  {(AuthService.getCurrentUser() && !appState.state.verified) && (
                    <span onClick={navVerify} style={{backgroundColor: "#336699", color: 'white',  padding: '10px 20px', borderRadius: "20px", fontWeight: 800}}>
                      Verify Account
                    </span>)
                  }
                </MDBNavbarLink>
              </MDBNavbarItem>     
              
              {/* username */}
              <MDBNavbarItem>
                <MDBNavbarLink>
                  {(AuthService.getCurrentUser()) && (<span onClick={navDashboard} style={{backgroundColor: "#131f59", color: 'white',  padding: '10px 20px', borderRadius: "20px", fontWeight: 500}}>
                    {appState.state.username}
                  </span>)}
                </MDBNavbarLink>
              </MDBNavbarItem>
              
              {/* bids */}
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}}>
                    <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/nabify_24.png" onClick={navBids} width="24" height="24"></img>
                    {
                      (appState.state.bids && <span className="badge-alt" style={{fontWeight: 500}} onClick={navBids}>    {appState.state.bids}</span>)
                    }
                  </span>
                </MDBNavbarLink>
              </MDBNavbarItem>
              
              {/* bell */}
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}}>
                    <MDBIcon color='dark'fas icon='bell'></MDBIcon>
                    {/* {
                      (appState.localNotifications.length > 0) && <MDBBadge pill color='danger'>5</MDBBadge>})
                    } */}
                  </span>
                </MDBNavbarLink>
              </MDBNavbarItem>

              {/* cart items */}
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}} onClick={navDashboard}>
                    <MDBIcon color='dark' fas icon='shopping-bag'></MDBIcon>
                    {
                      (appState.state.cart?.items && appState.state.cart?.items.length > 0 ? (<span onClick={navDashboard} className="badge-alt">{appState.state.cart.items.length}</span>) : (<></>))
                    }
                  </span>
                </MDBNavbarLink>
              </MDBNavbarItem>
              
              {/* login item */}
              <MDBNavbarItem>
                <MDBNavbarLink>
                {(!AuthService.getCurrentUser()) && (<span onClick={toggleLoginModal} style={{backgroundColor: "#131f59", color: 'white',  padding: '10px 20px', borderRadius: "10px", fontWeight: 800}}>
                    Log in
                  </span>)}
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      <MDBModal show={showLoginModal} setShow={setShowLoginModal}>
          <MDBModalDialog>
            <MDBModalContent   className="rounded" >
              <MDBModalBody>
                  <h4 className="text-end"><MDBIcon far icon="times-circle" onClick={toggleLoginModal}/></h4>
                  <br />
                  <h4 className="text-center" style={{color: "#333"}}>Log in to <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/nabifylogo.png" width="120"/> </h4>
                  <br />
                  <br />
                  <div className="vstack gap-4 px-4">
                    <div className="border text-center rounded py-2">
                      <button className="nabify-login" onClick={navigateToLogin}>Continue with Email</button>

                    </div>
                    <div className="border text-center rounded py-2 facebook-login">
                      <FacebookLogin
                        appId="319001158535270"
                        fields="name,email"
                        callback={responseFacebook}
                        disableMobileRedirect={true}
                        cssClass="reset"/>
                    </div>
                  </div>
                  {/* <div className="text-center">
                    <Button variant="outline-primary">
                      Use E-Mail
                    </Button>
                  </div>
                  <br />
                  <div className="text-center">
                    <FacebookLogin
                      appId="319001158535270"
                      fields="name,email"
                      callback={responseFacebook}
                      disableMobileRedirect={true}
                      cssClass="ua-facebook-login-button" />
                  </div> */}
                  <br />
                  <br />
                  <p style={{color: "#666"}} className="text-center">Don't have an account? <a style={{color: "#36F", fontWeight: "bolder"}} target="_blank" href="/register">Sign Up</a></p>
                  <br />
              </MDBModalBody>



              </MDBModalContent>
          </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default memo(Navigation);
