import "bootstrap/dist/css/bootstrap.min.css";

import * as Yup from "yup";

import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";

import React from "react";
import { passwordReset } from '../../services/auth.service';

interface PasswordResetFormValues {
  email: string;
}

const PasswordReset: React.FC<{}> = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate()

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string().required("This field is required!"),
  //   password: Yup.string().required("This field is required!"),
  // });

  const handlePasswordReset = (formValue: { email: string }) => {
    const { email } = formValue;

    passwordReset(email).then(
      () => {
        navigate("/login")
        // window.location.reload();
      },
      (error: {error: any}) => {
        // const resMessage =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  };

  const initialValues: {
    email: string
  } = {
    email: ""
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-container">
            <h1>Password Reset</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={handlePasswordReset}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">E-Mail</label>
                  <Field name="email" type="text" className="form-control" />
                </div>
                <br />
                <Link to="/login">
                  Back to Login
                </Link>
                <br />
                <div className="form-group" style={{textAlign: 'center'}}>
                  <br />
                  <button type="submit" className="btn btn-other" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Submit</span>
                  </button>
                </div>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PasswordReset;