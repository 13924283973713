import TokenService from "./token.service";
import api from "./api";

const getUserProfile = async () => {
  try {
    let result = await api.get("user")
    localStorage.setItem("username",result.data['username'])
    localStorage.setItem("email",result.data['email'])
    localStorage.setItem("verified",result.data["is_verified"])
    localStorage.setItem("unique_id",result.data["unique_id"])
    return {
      email: result.data['email'], 
      username: result.data['username'], 
      verified: result.data['is_verified'],
      profile: result.data['profile'],
      unique_id: result.data['unique_id'] 
    }
  } catch (e) {
    throw "User profile fetch error."
  }
}

const getBids = async () => {
    if (TokenService.getLocalAccessToken()) {
      return api.get('bids')
    } else {
      return Promise.reject("Not logged in.")
    }
}

const changeUsername = async (username) => {
    if (TokenService.getLocalAccessToken()) {
      return api.post('users/changeusername',{username})
    } else {
      return Promise.reject("Not logged in.")
    }
}

const changeEmail = async (email) => {
  return api.post('users/changeemail',{email})
}


const addCartItem = () => {
  return api.post("cartitem")
}

const removeCartItem = () => {
  return api.delete("cartitem")
}

const getInvoices = () => {
  return api.get("invoices")
}

const performCheckout = () => {
  return api.post("checkout")
}

const performCharge = () => {
  return api.post("charge")
}

const performAccountUpgrade = () => {
  return api.post("accounts/upgrade")
}

const getDonorProfile = (uid) => {
  return api.get("bids/profile?uid="+uid)
}

const performDonorGenerate = () => {
  return api.post("bids/profile-generate")
}


const UserService = {
  getBids,
  getUserProfile,
  getDonorProfile,
  addCartItem,
  removeCartItem,
  getInvoices,
  performCharge,
  performCheckout,
  performDonorGenerate,
  changeUsername,
  changeEmail,
  performAccountUpgrade
};
export default UserService;