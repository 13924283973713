import { AppStateActions, useAppState } from '../contexts/AppStateContext';
import React, { memo, useEffect, useMemo } from 'react';

import Listing from '../models/Listing';
import { io } from 'socket.io-client';

// import { useSocket } from '../contexts/SocketContext';

const ExchangeBox: React.FC = () =>  {

    const socketHost = process.env.NODE_ENV == "development" ? "http://mercury.nabify.com:5004" : "http://mercury.nabify.com:5004";
    const appState = useAppState()

    const socketInstance = useMemo(() => io(socketHost, {transports: ["websocket"]}), [socketHost]);

    useEffect(() => {
        socketInstance.on('connection',(msg) => {
            console.log("CONNECTED")
        })
    
        socketInstance.on('listing-update',(listings) => {
            let newListings: Listing[] = listings['live']
            appState.dispatch({type: AppStateActions.UpdateMultipleListings, value: newListings})
        })
    
        socketInstance.on('disconnected',(msg) => {
            console.log("DISCONNECTED")
        })
    },[])

    return (
        <>
        </>
    )
    
}


export default memo(ExchangeBox);