import "bootstrap/dist/css/bootstrap.min.css";

import * as Yup from "yup";

import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { useEffect, useState } from "react";

import React from "react";
import { register } from '../../services/auth.service';
import { useNavigate } from "react-router-dom"

interface RegisterFormValues {
  email: string;
  username: string;
  password: string;
}

const RegisterAlternate: React.FC<{}> = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate()

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string().required("This field is required!"),
  //   password: Yup.string().required("This field is required!"),
  // });

  const handleRegister = (formValue: { 
      email: string; 
      username: string;
      password: string;
      confirm: string; 
    }) => {
    const { email, username, password, confirm } = formValue;
    setMessage("");
    setLoading(true);

    register(email, username, password).then(
      () => {
        navigate("/dashboard")
        // window.location.reload();
      },
      (error: {error: any}) => {
        // const resMessage =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
        setLoading(false);
        // setMessage(resMessage);
      }
    );
  };

  let reqView = (
    <ul>
        <li>At least 8 characters</li>
        <li>At least 1 capital letter</li>
        <li>At least 1 number</li>
    </ul>
  )

  const initialValues: {
    email: string;
    username: string;
    password: string;
    confirm: string;
  } = {
    email: "",
    username: "",
    password: "",
    confirm: "",
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-container">
            <h1>Register</h1>
            <p>Your password will need...</p>
            {reqView}
            <Formik
              initialValues={initialValues}
              onSubmit={handleRegister}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">E-Mail</label>
                  <Field name="email" type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <Field name="username" type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field name="password" type="password" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Confirm Password</label>
                  <Field name="confirm" type="password" className="form-control" />
                </div>
                <div className="form-group" style={{textAlign: 'center'}}>
                  <br />
                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Enroll Now!</span>
                  </button>
                </div>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

// TODO:
export default RegisterAlternate;