import TokenService from "./token.service";
import api from "./api";
import axios from "axios";

//use instance instead of API for calls that don't need token auth
const prodConfig = {
  connectionURL: "https://nabify-neo-org-wsocket-production.up.railway.app/api/",
  projectId: "nabify-webapp"
};

const devConfig = {
  connectionURL: "http://localhost:8000/api/",
  projectId: "nabify-test-dev"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

const instance = axios.create({
  baseURL: config.connectionURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const register = (email, username, password) => {
  return api.post("/users/register", {
    user: {
      email,
      username,
      password
    }
  });
};

export const registerAlternateUser = (email, username, oneTimeToken, accessToken) => ({
  "provider":"facebook",
  "email": email,
  "username": username,
  "one_time_token": oneTimeToken,
  "access_token": accessToken
})

export const login = (username, password) => {
  return api
    .post("/auth/token/", 
      {"email": username, "password": password}
    )
    .then((response) => {
      console.log(response)
      if (response.data) {
        TokenService.setUser(response.data);
      }
      return response.data;
    }).catch((err) => {
      console.log(err)
    });
};

export const loginFB = (accessToken) => {
  return api
    .post("/auth/social/facebook/",
      {
        "provider":"facebook",
        "access_token":accessToken.accessToken
      }
    ).then((response) => {
      if (response.data) {
        TokenService.setUser(response.data);
      }
      return response.data;
    })
}

export const passwordReset = (email) => {
  return instance
    .post("/users/forgot", 
      {"email": email}
    )

    .then((response) => {
      console.log(response)
      if (response.data) {
        console.log("RETURN SUCCESS")
        // TokenService.setUser(response.data);
      }
      return response.data;
    }).catch((err) => {
      console.log(err)
    });
};

export const passwordResetActivate = (token) => {
  return instance
    .post("/users/activatepassword", 
      {token}
    )
    .then((response) => {
      return response
    }).catch((err) => {
      return Promise.reject(err)
    });
};

export const passwordResetChange = (token, password) => {
  return instance
    .post("/users/changepassword", 
      {token, password}
    )

    .then((response) => {
      console.log("PW C A")
      if (response.data) {
        console.log("CHANGE PASSWORD")
        // TokenService.setUser(response.data);
      }
      return response.data;
    }).catch((err) => {
      console.log(err)
    });
};

export const verifyUserActivate = () => {
  return api
    .post("/users/activateverify")
    .then((response) => {
      return response
    }).catch((err) => {
      return Promise.reject(err)
    });
};

export const verifyUserPerform = (token) => {
  return instance
    .post("/users/verify", 
      {token}
    )
    .then((response) => {
      console.log("VERIFY")
      if (response.data) {
        console.log("CHANGE PASSWORD")
        // TokenService.setUser(response.data);
      }
      return response.data;
    }).catch((err) => {
      console.log(err)
      throw err
    });
};


export const updateUsername = (username) => {
  return instance
    .post("/users/username",
      {username}
    ).then((response) => {

    }).catch(()=> {

    })
}

export const logout = () => {
  TokenService.removeUser();
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  registerAlternateUser,
  login,
  loginFB,
  logout,
  getCurrentUser,
  verifyUserActivate,
  verifyUserPerform,
  passwordResetActivate,
  passwordResetChange
};

export default AuthService;