import React from "react";
import Footer from "../../components/navigation/Footer";

export const About: React.FC = () => {
  return (
    <div style={{ marginTop: 40, paddingTop: 20 }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-1"></div>
          <div className="col-xs-10">
            <p>This area is coming soon!</p>
          </div>
          <div className="col-xs-1"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
