import "bootstrap/dist/css/bootstrap.min.css";

import * as Yup from "yup";

import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { Link, useLocation, useNavigate } from "react-router-dom"
import { passwordResetActivate, passwordResetChange } from '../../services/auth.service';
import { useEffect, useState } from "react";

import React from "react";

const PasswordResetActivate: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<string>("");
    const [reveal, setReveal] = useState<boolean>(false);
    const [token, setToken] = useState<string>("");
    const [showPasswords, setShowPasswords] = useState<boolean>(false); 

    let location = useLocation()
    let navigate = useNavigate()

    useEffect(() => {
      try {
        let token = location.pathname.split("/")[2]
        passwordResetActivate(token).then((res) => {
          setReveal(true)
          setToken(token)
          setLoading(false)            
        }).catch((e) => {
          setReveal(false)
          setLoading(false)
        })
      } catch (e) {
        setReveal(false)
      }
    }, [location])


    const handlePasswordResetChange = async (formValue: { password: string, confirm: string }) => {
      const { password, confirm } = formValue;
      try {
          if (password != confirm) {
            setMessage("These passwords do not match.")
          } 
          else if (password.length < 8) {
            setMessage("Password must have at least 8 characters.")
          }
          else if (!/[A-Z]/.test(password)) {
            setMessage("Password must have at least one capital letter.")
          }
          else if (!/[0-9]/.test(password)) {
            setMessage("Password must have at least one number")
          }
          else {
            try {
              await passwordResetChange(token, password)
              alert("Your password has been succesfully updated!")
              navigate("/login", {replace: true})
            } catch (e) {
              alert(e)
            }
            
          }

      } catch (e) {
          console.log(e)
      }
    }
  
    const initialSetupResetValues: {
        token: string,
        password: string,
        confirm: string
        } = {
        token: "",
        password: "",
        confirm: ""
    };  

    let reqView = (
      <ul>
          <li>At least 8 characters</li>
          <li>At least 1 capital letter</li>
          <li>At least 1 number</li>
      </ul>
    )

    let view = (
      <Formik
        initialValues={initialSetupResetValues}
        onSubmit={handlePasswordResetChange}

       >
        <Form>
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <div>
          <Field type={showPasswords ? 'text' : 'password'} name="password" className="form-control" />
          </div>
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="confirm">Confirm Password</label>
          <Field type={showPasswords ? 'text' : 'password'} name="confirm" className="form-control" />
          {!showPasswords && <button style={{float:'right'}} onClick={()=>{setShowPasswords(!showPasswords)}}>Reveal</button>}
          {showPasswords && <button style={{float:'right'}} onClick={()=>{setShowPasswords(!showPasswords)}}>Hide</button>}
        </div>
        <br />
        <br />
        <hr />
        <div className="form-group" style={{textAlign: 'center'}}>
          <br />
          <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Submit</span>
          </button>
        </div>
        <br />
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
      </Form>
      </Formik>
    )
    

    return (
    <div className="container">
        <div className="row">
        <div className="col-md-12">
            <div className="card card-container">
            <h3>Reset Password</h3>
            <p>New passwords must contain:</p>
            {reveal && reqView}
            {loading && <p>Please wait...</p>}
            {reveal ? view : <p>This forget password request has already been processed. Use the login page to find the Reset Password link.</p>}
            </div>
        </div>
        </div>
    </div>
    );
};
export default PasswordResetActivate;