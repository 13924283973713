import api from "../api";

const getProduct = (unique_id) => {
    return api.get('product/?id='+unique_id+'&=');
}

const getProductList = () => {
    return api.get('manage/myProducts')
}

const createProduct = (data) => {
    return api.post("manage/products",data)
}

const updateProduct = (data) => {
    return api.put("manage/products",data)
}

const deleteProduct = (unique_id) => {
    return api.delete("manage/products/?id=" + unique_id)
}

const createAsset = (formData) => {
    return api.post("manage/assets/upload", formData, {
        "Content-Type": "multipart/form-data"
    })
}


const ProductManageService = {
    getProduct,
    getProductList,
    createProduct,
    updateProduct,
    deleteProduct,
    createAsset
};

  
export default ProductManageService;
