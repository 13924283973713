import api from "./api";

const getCart = () => {
    return api.get("cart")
}

const removeItemFromCart = (unique_id) => {
    return api.post("cartitem",{unique_id})
}

const validateCart = () => {
    return api.post("checkout")
}

const performCharge = () => {
    return api.post('charge')
}

const validateCharge = (addressBlock) => {
    return api.post('charge/create', addressBlock)
}

const verifyShipping = (address) => {
    return api.post('ship', address)
}

const CartService = {
    getCart,
    removeItemFromCart,
    validateCart,
    performCharge,
    validateCharge,
    verifyShipping
};
export default CartService;