import {
    MDBBadge,
    MDBBtn,
    MDBIcon
} from "mdb-react-ui-kit";
import { memo, useEffect, useState } from "react";

import AppService from "../../services/app.service";
import AuthService from "../../services/auth.service";
import React from "react";
import { useAppState } from "../../contexts/AppStateContext";
import { useNavigate } from "react-router-dom";

const NavigationMobile: React.FC = () => {
  
    const [showModal, setShowModal] = useState(false);
  
    const navigate = useNavigate();
    const appState = useAppState();
  
    useEffect(() => {
  
      (async () => {
        AppService.refreshAppState(appState)
      })()
      
      document.addEventListener("click", toggleModal);
    }, []);
  
    const navHome = () => {
      navigate("/home");
    };
  
    const navDashboard = () => {
      if (AuthService.getCurrentUser()) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    }
  
    const navVerify= () => {
      if (AuthService.getCurrentUser()) {
        navigate("/verify");
      } else {
        navigate("/login");
      }
    }
  
    const navBids = () => {
      navigate('/buybids')
    }
  
    function handleClick() {
      setShowModal(!showModal);
    }
  
    function toggleModal(e: any) {
      let bellButton = document.getElementById("bell-icon");
      if (
        e.target !== bellButton &&
        e.target.className !== "notifications" &&
        e.target.className !== "group-header-new" &&
        e.target.className !== "group-header-earlier"
      ) {
        setShowModal(false);
      }
    }
  
    return (
      <>
        {
            (AuthService.getCurrentUser()) ? 
            (        
                <div onClick={navBids} className="d-block d-sm-none button-mobile-fixed" style={{top: '80px', zIndex: 100}}>
                    <div className="button-mobile-fixed-content">{appState.state.bids} {' '} {appState.state.bids && (appState.state.bids == 1 ) ? 'BID' : 'BIDS'}</div>
                </div>
            ) 
            : 
            (
            <></>
            )
        }

        
      </>
    );
  };
  export default memo(NavigationMobile);
  