import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AuthService from "../services/auth.service";
import { Banner } from "../components/banner/Banner";
import BidpackService from "../services/bidpack.service";
import Footer from "../components/navigation/Footer";
import FooterEnd from "../components/navigation/FooterEnd";
import HomeListing from "../components/listing/HomeListing";
import Important from "../components/Important";
import React from "react";
import SocketContext from "../contexts/SocketContext";
import io from "socket.io-client";

const Home: React.FC = () => {
  // const [user, setUser] = useState()
  // const [error, setError] = useState("")
  // const [bidPack, setBidPack] = useState<IBidpacks>({});

  const navigate = useNavigate();
  const pathname = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  },[pathname]);
  // const socket = useContext(SocketContext);

//   const handleBidPackUpdate = useCallback(() => {
//     setBidPack(bidPack);
//   }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let { data } = await BidpackService.getBidpacks();
  //       let bidArray = data.data
  //       let bidDict: IBidpacks = {}
  //       bidArray.forEach((obj: Bidpacks) => {
  //           bidDict[obj.unique_id] = obj
  //           bidDict.socket = socket
  //           socket.on(obj.unique_id, function (msg: Bidpacks) {
  //               // console.log(bidPack)
  //               bidPack[msg.unique_id] = msg;
  //               setBidPack(bidPack);
  //               // console.log(bidPack, idx);
  //           });
  //       })

  //       console.log(bidDict)
  //       setBidPack(bidDict)
  //       // let bidPackArray = [];
  //       // data.data.forEach(function (obj: Bidpacks, idx: any) {

  //       //     // console.log(idx);


            
  //       //     setBidPack(data.data);

  //       // })

  //   } catch (error) {
  //           console.error(error);
  //       }
  //   })();
  // }, []);

  // return (
  //   <div>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-sm">
  //           <button className="btn-full btn-primary center">
  //             <i className="fa fa-table"></i> Auctions
  //           </button>
  //           {/* {Object.keys(bidPack).length > 0
  //             ? console.log(bidPack, "test")
  //             : console.log("nothin")} */}
  //           {console.log(bidPack)}
  //           {console.log("-------------")}
  //           {Object.keys(bidPack).map((key) => {
  //             return (
  //               <div
  //                 key={key}
  //                 style={{ backgroundColor: `${bidPack[key].color}` }}
  //               >
  //                 {bidPack[key].name}
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //     <Footer />
  //   </div>
  // );

  return (
    <>
      <Banner />    
      <Important />
      <HomeListing />
      <Footer />
      <FooterEnd />
    </>

  )
};
export default Home;
