import api from "./api";

const getLiveListings = () => {
    return api.get('listing/live/');
};

const getIndividualListing = (unique_id) => {
    return api.get('list?id='+unique_id);
}

const bidOnListing = (unique_id) => {
    return api.post('listing/bid/',{unique_id})
}

const bidOnSilentListing = (unique_id) => {
    return api.post('listing/bidsilent/',{unique_id})
}

const setAutobid = (unique_id, count, setStart) => {
    return api.post('listing/setauto/', {unique_id, count, start_at: setStart})
}

const endAutobid = (unique_id) => {
    return api.post('listing/endauto/', {unique_id})
}

const ListingService = {
    getLiveListings,
    getIndividualListing,
    bidOnListing,
    bidOnSilentListing,
    setAutobid,
    endAutobid,
};

export default ListingService;
